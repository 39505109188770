import { FC, useContext, useState } from 'react'

import { Select } from '@jume/ui'
import { useProfile } from 'packages/app/modules/profile'
import { useSearchProject, useSwitchProject } from 'packages/app/modules/projects/queries'
import { AppContext } from 'packages/app/providers'

interface ProjectSelectProps {
  className?: string
}

export const ProjectSelect: FC<ProjectSelectProps> = ({ className }) => {
  const [searchValue, setSearchValue] = useState('')

  const appContext = useContext(AppContext)

  const { projects, isLoading, hasNextPage, fetchNextPage } = useSearchProject(searchValue)
  const { switchProject } = useSwitchProject()
  const { profile } = useProfile()

  return (
    <Select
      className={className}
      disabled={appContext.modules.project.disabledSelect}
      hasNextPage={hasNextPage}
      isOptionEllipsis
      loading={isLoading}
      onChange={switchProject}
      onScrollPaginate={fetchNextPage}
      onSearch={setSearchValue}
      options={projects.map((item) => ({
        value: item.id,
        label: item ? item.title : '',
      }))}
      showSearch
      value={!isLoading ? profile?.activeProjectId : undefined}
    />
  )
}
