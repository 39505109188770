import { useLocation, useNavigate } from '@tanstack/react-router'
import { Streams } from 'constants/streams'
import { Path } from 'interfaces/router.interfaces'
import { ProjectTypes } from 'opti/shared/constants/projectTypes'
import { Project } from 'packages/app'

const projectTypeMap: Record<ProjectTypes, Path> = {
  [ProjectTypes.PromoOptimizer]: `/${Streams.Opti}/promo-optimizer`,
  [ProjectTypes.RtmOptimizer]: `/${Streams.Opti}/rtm-optimizer`,
  [ProjectTypes.TetrisOptimizer]: `/${Streams.Opti}/tetris-optimizer`,
  [ProjectTypes.CfrOptimizer]: `/${Streams.Opti}/cfr-optimizer`,
}

const notRedirectLocations = [
  `/${Streams.Opti}/create-promo-scenario`,
  `/${Streams.Opti}/create-rtm-scenario`,
  `/${Streams.Opti}/create-tetris-scenario`,
  `/${Streams.Opti}/create-cfr-scenario`,
]

export const useProjectType = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (project: Project) => {
    const typeCode = project?.type.code as ProjectTypes
    if (typeCode && !pathname.startsWith(projectTypeMap[typeCode]) && !notRedirectLocations.includes(pathname)) {
      navigate({ to: projectTypeMap[typeCode] })
    }
  }
}
