import { t } from '@jume/localization'
import { Streams } from 'constants/streams'
import { ModuleConnector } from 'packages/app'

export const demandMasterDataModule: ModuleConnector = (options) => ({
  ...options,
  menuItems: {
    masterData: {
      label: () => t('menu.masterDataDB'),
      dependence: (appContext) =>
        appContext.modules.permissions?.viewMasterdataUploadDashboard ||
        appContext.modules.permissions?.viewMasterdataEditDashboard,
      items: [
        {
          label: () => t('menu.sourceLoading'),
          href: `/${Streams.Demand}/master-data-upload`,
          dependence: (appContext) => appContext.modules.permissions?.viewMasterdataUploadDashboard,
        },
        {
          label: () => t('menu.dataHandling'),
          href: `/${Streams.Demand}/data-handling`,
          dependence: (appContext) => appContext.modules.permissions?.viewMasterdataEditDashboard,
        },
      ],
    },
  },
})
