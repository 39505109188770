import { defaultPagination, GetArrayResponse, GetOneResponse } from '@jume/api'
import { axios } from '@jume/core'
import { deleteEmpty } from '@jume/utils'
import { defaultFilterValuesPerPage } from 'constants/pagination'
import { Streams } from 'constants/streams'
import { FilterParams, SelectData } from 'prgm/shared/api'

import { scorecardEndpoints } from './endpoints'
import {
  CalculateScenarioOptions,
  ControlYearOption,
  Scorecard,
  ScorecardCalculate,
  TargetYearOption,
} from './interfaces'

const getScenarios = async (): GetOneResponse<Scorecard> => {
  const { data } = await axios.request({
    method: 'GET',
    ...scorecardEndpoints.scenarios(),
    tracing: {
      stream: Streams.Prgm,
      module: 'scorecard',
      methodName: 'getScenarios',
    },
  })
  return data
}

const calculateScenarios = async ({
  scenarios,
  filters,
  differenceType,
  controlYear,
  targetYear,
  timeGranularity,
  periodEnd,
  periodStart,
}: CalculateScenarioOptions): GetOneResponse<ScorecardCalculate> => {
  const { data } = await axios.request({
    method: 'POST',
    ...scorecardEndpoints.calculate(),
    data: deleteEmpty({
      scenarios,
      filters,
      differenceType,
      controlYear,
      targetYear,
      timeGranularity,
      periodEnd,
      periodStart,
    }),
    tracing: {
      stream: Streams.Prgm,
      module: 'scorecard',
      methodName: 'calculateScenarios',
    },
  })
  return data
}

const getControlYearOptions = async (): GetArrayResponse<ControlYearOption> => {
  const { data } = await axios.request({
    method: 'GET',
    ...scorecardEndpoints.controlYear(),
    tracing: {
      stream: Streams.Prgm,
      module: 'scorecard',
      methodName: 'getControlYearOptions',
    },
  })
  return data
}

const getTargetYearOptions = async (): GetArrayResponse<TargetYearOption> => {
  const { data } = await axios.request({
    method: 'GET',
    ...scorecardEndpoints.targetYear(),
    tracing: {
      stream: Streams.Prgm,
      module: 'scorecard',
      methodName: 'getTargetYearOptions',
    },
  })
  return data
}

const searchBudgetType = async ({ search, pagination, filters }: FilterParams): GetArrayResponse<SelectData> => {
  const { data } = await axios.request({
    method: 'POST',
    ...scorecardEndpoints.budgetType(),
    params: {
      ...defaultPagination(pagination, defaultFilterValuesPerPage),
      search,
    },
    data: {
      bindFilters: filters,
    },
    tracing: {
      stream: Streams.Prgm,
      module: 'scorecard',
      methodName: 'searchBudgetType',
    },
  })
  return data
}

export const scorecardApi = {
  getScenarios,
  calculateScenarios,
  getTargetYearOptions,
  getControlYearOptions,
  searchBudgetType,
}
