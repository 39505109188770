import { useCallback, useState } from 'react'

import { useSafeState } from 'ahooks'

type Data<T> = T | null
type OnOpenFn<T> = (data?: T) => void

interface UseModalProps<T> {
  initialOpen?: boolean
  initialData?: Data<T>
}

type UseModalReturnType<T> = [boolean, OnOpenFn<T>, VoidFunction, { isMounted: boolean; data: Data<T> }]

export const useModal = <T>(
  { initialOpen = false, initialData = null }: UseModalProps<T> = { initialOpen: false, initialData: null },
): UseModalReturnType<T> => {
  const [isOpen, setIsOpen] = useState<boolean>(initialOpen)
  const [isMounted, setIsMounted] = useSafeState<boolean>(initialOpen)
  const [data, setData] = useState<Data<T>>(initialData)

  const open = useCallback((newData?: T) => {
    if (newData) {
      setData(newData)
    }
    setIsOpen(true)
    setIsMounted(true)
  }, [])

  const close = useCallback(() => {
    setIsOpen(false)
    setTimeout(() => {
      setIsMounted(false)
      setData(null)
    }, 300)
  }, [])

  return [isOpen, open, close, { isMounted, data }]
}
