import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'packages/app'

export const Route = createFileRoute('/deploy/scenario-data-hub/$scenarioId/$cardType')({
  component: createPage({
    titleKey: 'titles.deployDataHubTable',
    lazyImport: {
      fn: () => import('deploy/pages/dataHubScenarioTable/DataHubScenarioTable'),
      name: 'DataHubScenarioTable',
    },
  }),
})
