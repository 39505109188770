import { devtools } from '@jume/utils'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export interface UseSession {
  userId: number | null
  setUserId: (userId: number | null) => void
  activeProjectId: number | null
  setActiveProjectId: (activeProjectId: number | null) => void
  page: string | null
  setPage: (page: string | null) => void
  clear: () => void
}

export const useSession = create<UseSession>()(
  devtools(
    persist(
      (set) => ({
        userId: null,
        setUserId: (userId) => set({ userId }),
        activeProjectId: null,
        setActiveProjectId: (activeProjectId) => set({ activeProjectId }),
        page: null,
        setPage: (page) => set({ page }),
        clear: () =>
          set({
            userId: null,
            activeProjectId: null,
            page: null,
          }),
      }),
      {
        name: 'app.session',
      },
    ),
    {
      store: 'app.session',
    },
  ),
)
