import { useContext } from 'react'

import { AppContext } from 'packages/app/providers'

import { useLogoutByAppContext } from './useLogoutByAppContext'

export const useLogout = () => {
  const appContext = useContext(AppContext)
  return useLogoutByAppContext(appContext)
}
