import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'packages/app'

export const Route = createFileRoute('/deploy/stock-allocation/$scenarioId')({
  component: createPage({
    titleKey: 'titles.stockAllocation',
    lazyImport: {
      fn: () => import('deploy/pages/stockAllocation/StockAllocation'),
      name: 'StockAllocation',
    },
  }),
})
