import { useCallback } from 'react'

import { setLang } from '@jume/localization'
import { AppState } from 'packages/app/appState'
import { profileApi } from 'packages/app/modules/profile/api'
import { Stream } from 'packages/app/modules/streams'

export const useLoadProfile = (appState: AppState) => {
  const getProfile = useCallback(async () => {
    await profileApi.getProfile().then(async (profile) => {
      await setLang(profile.language.code)
      if (appState.modules) {
        appState.modules.profile = profile
        profile.streams.forEach((profileStream) => {
          const stream = appState.modules?.streams[profileStream.title.toLowerCase()] as Stream | undefined
          if (stream) {
            stream.entity = profileStream || null
          }
        })
        if (appState.auth?.logout) {
          appState.auth.logout.clearProfile = () => {
            if (appState.modules) {
              appState.modules.profile = null
              appState.modules.stream.setStream(null)
            }
          }
        }
      }
    })
  }, [])

  return { getProfile }
}
