import { GetAllData } from '@jume/api'
import { PaginateData } from '@jume/query'
import { PromoTableRow } from 'jume/promoTable/api'

export enum PromoTabsCodes {
  All = 'all',
  Regular = 'regular',
  Archive = 'archive',
}

export type PromoAllData = Record<PromoTabsCodes, PaginateData<GetAllData<PromoTableRow>> | undefined>
