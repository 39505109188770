import { Languages } from '@jume/localization'
import { Granularities } from 'constants/granularities'

export const months: Record<Languages, string[][]> = {
  ru: [
    ['январь', 'января'],
    ['февраль', 'февраля'],
    ['март', 'марта'],
    ['апрель', 'апреля'],
    ['май', 'мая'],
    ['июнь', 'июня'],
    ['июль', 'июля'],
    ['август', 'августа'],
    ['сентябрь', 'сентября'],
    ['октябрь', 'октября'],
    ['ноябрь', 'ноября'],
    ['декабрь', 'декабря'],
  ],
  en: [
    ['january', 'january'],
    ['february', 'february'],
    ['march', 'march'],
    ['april', 'april'],
    ['may', 'may'],
    ['june', 'june'],
    ['july', 'july'],
    ['august', 'august'],
    ['september', 'september'],
    ['october', 'october'],
    ['november', 'november'],
    ['december', 'december'],
  ],
}

export const monthsCapitalize: Record<Languages, string[][]> = {
  ru: [
    ['Январь', 'Января'],
    ['Февраль', 'Февраля'],
    ['Март', 'Марта'],
    ['Апрель', 'Апреля'],
    ['Май', 'Мая'],
    ['Июнь', 'Июня'],
    ['Июль', 'Июля'],
    ['Август', 'Августа'],
    ['Сентябрь', 'Сентября'],
    ['Октябрь', 'Октября'],
    ['Ноябрь', 'Ноября'],
    ['Декабрь', 'Декабря'],
  ],
  en: [
    ['January', 'January'],
    ['February', 'February'],
    ['March', 'March'],
    ['April', 'April'],
    ['May', 'May'],
    ['June', 'June'],
    ['July', 'July'],
    ['August', 'August'],
    ['September', 'September'],
    ['October', 'October'],
    ['November', 'November'],
    ['December', 'December'],
  ],
}

export const monthsShort: Record<Languages, string[]> = {
  ru: ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'],
  en: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
}

export const monthsCapitalizeShort: Record<Languages, string[]> = {
  ru: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
  en: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
}

export const periods: Record<Languages, Record<Granularities, string>> = {
  ru: {
    days: 'В днях',
    weeks: 'В неделях',
    months: 'В месяцах',
    quarters: 'В кварталах',
    years: 'В годах',
  },
  en: {
    days: 'Days',
    weeks: 'Weeks',
    months: 'Months',
    quarters: 'Quarters',
    years: 'Years',
  },
}

export const weekDays: Record<Languages, string[]> = {
  ru: ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'],
  en: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
}

export const weekDaysShort: Record<Languages, string[]> = {
  ru: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
  en: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
}

export const workDays: Record<Languages, string[]> = {
  ru: ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница'],
  en: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
}
