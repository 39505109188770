import { FC, ReactNode } from 'react'

import { t } from '@jume/localization'
import { Loader, LoaderTypes } from '@jume/ui'
import { useRouterState } from '@tanstack/react-router'
import { useTitle } from 'packages/app'

interface Props {
  children?: ReactNode
  title?: string
  titleTranslateKey?: Parameters<typeof t>[0]
}

export const NotFoundBlock: FC<Props> = ({ children, title, titleTranslateKey }) => {
  useTitle(titleTranslateKey ?? 'titles.404', undefined, title)

  const { isLoading } = useRouterState()

  if (isLoading) {
    return <Loader className="bg-white" type={LoaderTypes.SpinnerCenter} />
  }

  if (children) {
    return children
  }

  return (
    <div className="full-center">
      <h1>404</h1>
    </div>
  )
}
