import { FC } from 'react'

import { ApplicationConfig } from '@jume/core'
import { Outlet } from '@tanstack/react-router'
import { useAppState } from 'packages/app/appState'
import { LoaderController } from 'packages/app/layout/RootLayout'
import { useLayout } from 'packages/app/layout/useLayout'
import { AppProvider } from 'packages/app/providers'

interface Props {
  applicationConfig: ApplicationConfig
}

export const AppLayout: FC<Props> = ({ applicationConfig }) => {
  const appState = useAppState(applicationConfig)
  const { Layout } = useLayout()

  return (
    <AppProvider {...appState}>
      <LoaderController>
        <Layout>
          <Outlet />
        </Layout>
      </LoaderController>
    </AppProvider>
  )
}
