import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'packages/app'

export const Route = createFileRoute('/prgm/approve')({
  component: createPage({
    titleKey: 'titles.prgmApprove',
    lazyImport: {
      fn: () => import('prgm/approvePromo/components/ApproveTableBlock'),
      name: 'ApproveTableBlock',
    },
  }),
})
