import { useMemo } from 'react'

import { ApplicationConfig } from '@jume/core'
import { AppState } from 'packages/app/appState'
import { AppModulesState, useLoadPermissions, useLoadProfile } from 'packages/app/modules'
import { applySessionActions, useSessionClearFunctions } from 'packages/app/modules/session'
import { defineCurrentStream } from 'packages/app/modules/streams'

export const useInitialRequests = (
  applicationConfig: ApplicationConfig,
  appState: AppState,
): Pick<AppModulesState, 'initialRequests'> => {
  const { getProfile } = useLoadProfile(appState)
  const { getPermissions } = useLoadPermissions(applicationConfig, appState)

  const clearFunctions = useSessionClearFunctions(applicationConfig, appState)

  const initialRequests: AppModulesState['initialRequests'] = useMemo(
    () => ({
      run: async (authBuilder) => {
        await Promise.allSettled([getProfile(), getPermissions()])
        const currentStream = defineCurrentStream(appState)
        if (currentStream) {
          appState.modules?.stream.setStream(currentStream)
        }
        if (currentStream?.session?.enabled) {
          authBuilder.apply<AppModulesState>((builder) => applySessionActions(builder, clearFunctions.current))
        }
      },
      refetch: async () => {},
    }),
    [],
  )

  return { initialRequests }
}
