import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'packages/app'

export const Route = createFileRoute('/ppds/materials')({
  component: createPage({
    titleKey: 'titles.ppdsMaterials',
    lazyImport: {
      fn: () => import('ppds/materials/components/MaterialsBlock'),
      name: 'MaterialsBlock',
    },
  }),
})
