import { createEndpoint, NoParamEndpointConstructor } from '@jume/core'

const baseUrl = '/prgm/scorecard'

interface Endpoints {
  scenarios: NoParamEndpointConstructor
  calculate: NoParamEndpointConstructor
  controlYear: NoParamEndpointConstructor
  targetYear: NoParamEndpointConstructor
  budgetType: NoParamEndpointConstructor
}

export const scorecardEndpoints: Endpoints = {
  scenarios: () => createEndpoint(`${baseUrl}/scenarios`),
  calculate: () => createEndpoint(`${baseUrl}/calculate`),
  controlYear: () => createEndpoint(`${baseUrl}/filters/control-year`),
  targetYear: () => createEndpoint(`${baseUrl}/filters/target-year`),
  budgetType: () => createEndpoint(`${baseUrl}/filters/budget-type`),
}
