import { useState } from 'react'

import { ApplicationConfig, StreamOptions } from '@jume/core'
import { useNavigate } from '@tanstack/react-router'
import { AppState } from 'packages/app/appState'
import { AppModulesState, defineCurrentStream, isNotDefinedStreamPage, Stream } from 'packages/app/modules'
import { useEnableSession } from 'packages/app/modules/session'

export const useStreamsInit = (
  applicationConfig: ApplicationConfig,
  appState: AppState,
): Pick<AppModulesState, 'stream' | 'streams'> => {
  const [currentStream, setCurrentStream] = useState<Stream | null>(null)
  const navigate = useNavigate()
  const { enableSession } = useEnableSession(appState)

  const setStream = (stream: StreamOptions | Stream | null) => {
    setCurrentStream(stream as Stream)
    if (stream) {
      enableSession(stream)
      if (stream !== defineCurrentStream(appState) || isNotDefinedStreamPage(stream)) {
        navigate({ to: stream.startPage })
      }
    }
  }

  return {
    stream: {
      currentStream,
      setStream,
    },
    streams: applicationConfig.streams,
  }
}
