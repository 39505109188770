import { useContext, useEffect, useState } from 'react'

import { ApiErrorsCodes } from '@jume/core'
import { useWs } from 'packages/app'
import { useCurrentStream } from 'packages/app/modules/streams'
import { SystemUpdating } from 'packages/app/modules/systemUpdating/api'
import {
  checkUpdateStatusInterval,
  gitlabDeployInfo,
  typeSendMessageDeployInfo,
} from 'packages/app/modules/systemUpdating/constants'
import { useSystemUpdatingQuery } from 'packages/app/modules/systemUpdating/queries'
import { AppContext } from 'packages/app/providers'

export const useUpdatingSystem = () => {
  const [isFutureUpdating, setIsFutureUpdating] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const appContext = useContext(AppContext)
  const { currentStream } = useCurrentStream()

  const { data, refetch, error } = useSystemUpdatingQuery()

  const customWsStreams = Object.values(appContext.modules.streams).filter(
    (stream) => stream.systemUpdating?.customWsHook,
  )

  const enabledCustomHook = !!currentStream && customWsStreams.includes(currentStream)

  const isUpdatingWS = Boolean(
    customWsStreams
      .map((stream) => stream.systemUpdating?.customWsHook?.(customWsStreams.includes(stream)))
      .find((result) => result?.isUpdatingWS)?.isUpdatingWS,
  )

  useWs(gitlabDeployInfo, {
    enabled: !enabledCustomHook,
    onPublish: {
      [typeSendMessageDeployInfo]: (dataWS: SystemUpdating) => {
        setIsFutureUpdating(dataWS?.status)
        if (dataWS?.status) {
          setTimeout(() => refetch(), checkUpdateStatusInterval)
        }
      },
    },
    module: 'app.systemUpdating',
  })

  useEffect(() => {
    if (isUpdatingWS) {
      setIsFutureUpdating(true)
      setTimeout(() => refetch(), checkUpdateStatusInterval)
    }
  }, [isUpdatingWS])

  useEffect(() => {
    if (data) {
      setIsUpdating(false)
      setIsFutureUpdating(data.status)
    }
  }, [data])

  useEffect(() => {
    if (error?.code === ApiErrorsCodes.UnknownError) {
      setIsUpdating(true)
      setIsFutureUpdating(false)
    }
  }, [error])

  return { isFutureUpdating, isUpdating }
}
