import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'packages/app'

export const Route = createFileRoute('/deploy/scenario-settings/$scenarioId')({
  component: createPage({
    titleKey: 'titles.deployScenarioSettings',
    lazyImport: {
      fn: () => import('deploy/pages/scenario/components/DeployScenarioSettings'),
      name: 'DeployScenarioSettings',
    },
  }),
})
