import { useContext, useMemo } from 'react'

import { MenuItem } from '@jume/core'
import { AppContext } from 'packages/app/providers'

export const useMenu = (): MenuItem[] => {
  const appContext = useContext(AppContext)

  useMemo(() => {
    if (!appContext.menuBuilder) {
      return
    }
    appContext.menuBuilder.init(appContext)
    appContext.modules.menuCreator(appContext)
  }, [appContext.menuBuilder, appContext.modules.stream.currentStream, appContext.modules.project.currentProject])

  return appContext.menuBuilder?.getMenu() || []
}
