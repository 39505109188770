import { FC } from 'react'

import cx from 'clsx'
import { ratingIcons } from 'modules/feedback/constants/ratingIcons'

import classes from './Rating.module.scss'

interface Props {
  onChange: (value: number) => void
  value?: number | null
}

export const Rating: FC<Props> = ({ value, onChange }) => (
  <div className={classes.container}>
    {ratingIcons.map((rating) => (
      <span
        className={cx(classes.ratingIcon, { [classes.active]: value === rating.rating })}
        key={rating.rating}
        onClick={() => {
          onChange(rating.rating)
        }}
      >
        {value === rating.rating ? rating.activeIcon : rating.icon}
      </span>
    ))}
  </div>
)
