export enum PrgmRoles {
  Creator = 'Creator PRGM',
  CreatorPromoTeam = 'Creator PROMO TEAM',
  SalesCoordinator = 'Sales coordinator',
  FinanceApprover = 'Finance approver',
  PriceMd = 'Price MD',
  FerreroCoordinator = 'Ferrero coordinator',
  SourcingApprover = 'Sourcing approver',
  Administrator = 'Administrator',
}
