import { Streams } from 'constants/streams'
import { StreamConnector } from 'packages/app'

import { milkDataHubModule } from './dataHub/milkDataHubModule'

const milkPlanningModules = {
  dataHub: milkDataHubModule(),
}

export const connectMilkPlanningStream: StreamConnector = () => ({
  name: Streams.MilkPlanning,
  startPage: `/${Streams.MilkPlanning}/data-hub`,
  modules: milkPlanningModules,
  menu: (menuBuilder) => {
    menuBuilder.addItem({
      ...milkPlanningModules.dataHub.menuItems.dataHub,
      initialOpen: true,
      items: [milkPlanningModules.dataHub.menuItems.dataManagement],
    })
  },
})
