import { t } from '@jume/localization'
import { ColumnDef } from '@tanstack/table-core'
import { Difference } from 'prgm/scorecard/api'
import { DifferenceCell } from 'prgm/scorecard/components/Difference/DifferenceCell'
import { DifferenceDelete } from 'prgm/scorecard/components/DifferenceDelete'
import { DifferenceRow } from 'prgm/scorecard/interfaces'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

interface UseDifference<TRow extends DifferenceRow = DifferenceRow> {
  rows: TRow[]
  difference: Difference
  columns: ColumnDef<any, any>[]
  columnsLeft: ColumnDef<any, any>[]
  columnsRight: ColumnDef<any, any>[]
  setDifference: (difference: Difference) => void
  setRows: (rows: { name: string }[]) => void
  addRows: (ids: number[]) => void
  removeRow: (id: number) => void
}

export const useDifference = create<UseDifference>()(
  immer((set) => ({
    rows: [],
    columns: [],
    columnsLeft: [],
    columnsRight: [],
    difference: {
      columns: [],
      rows: [],
    },
    setDifference: (difference) =>
      set(() => {
        const columns = difference.columns.map((column) => {
          const month = column.date.slice(0, 2)
          const year = column.date.slice(-4)

          return {
            accessorKey: `${month}${year}`,
            id: `${month}${year}`,
            header: column.name,
            cell: DifferenceCell,
          } as ColumnDef<any, any>
        })

        return {
          difference,
          columns,
          columnsLeft: [{ accessorKey: 'name', id: 'name', header: 'Name' }],
          columnsRight: [
            {
              id: 'delete',
              accessorKey: 'delete',
              header: t('prgmCreate.delete'),
              cell: DifferenceDelete,
            },
          ],
        }
      }),
    setRows: (newRows) =>
      set({
        rows: newRows.map((row, index) => ({
          ...row,
          id: index,
          isSelected: false,
        })),
      }),
    addRows: (ids) =>
      set(({ rows }) => {
        rows.forEach((row) => {
          if (ids.includes(row.id)) {
            row.isSelected = true
          }
        })
      }),
    removeRow: (id) =>
      set(({ rows }) => {
        const row = rows.find((el) => el.id === id)
        if (row) {
          row.isSelected = false
        }
      }),
  })),
)
