import { DataSourceUploadExtendedTypes, DataSourceUploadTypes } from 'interfaces/components.interfaces'

export enum CfrScenarioTabsCodes {
  Input = 'Input',
  ParamsForRun = 'ParamsForRun',
  Output = 'Output',
}

export enum CfrUploadStatuses {
  Failed = 'failed',
  Success = 'success',
  Loading = 'loading',
  Idle = 'idle',
}

export enum CfrDSTypes {
  SafetyDays = 'safetyDays',
  Coef = 'coef',
  Routes = 'routes',
  Moq = 'moq',
  FC = 'fc',
  Fact = 'fact',
  Quarantine = 'quarantine',
  StepTable = 'stepTable',
  DLC = 'dlc',
  ShipmentFreq = 'shipmentFreq',
  Costs = 'costs',
  HubbingDays = 'hubbingDays',
  MdLocations = 'mdLocations',
  UOM = 'uom',
  DeltaFaBias = 'deltaFaBias',
  MinCfrMaxPped = 'minCfrMaxPped',
  Filters = 'filters',
}

export interface CfrDataSource {
  type: DataSourceUploadTypes | DataSourceUploadExtendedTypes | null
  isUploaded: boolean
  title: string
  isLoading: boolean
  date?: string | null
  error?: boolean
}
