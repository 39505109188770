import { useCallback } from 'react'

import { ApplicationConfig } from '@jume/core'
import { AppState } from 'packages/app/appState'

import { Permission, permissionsApi, PermissionsData } from './api'
import { checkPermission } from './helpers/checkPermission'

export const useLoadPermissions = (applicationConfig: ApplicationConfig, appState: AppState) => {
  const getPermissions = useCallback(async () => {
    await permissionsApi.getPermissions().then((data) => {
      if (!applicationConfig.permissionsEnum) {
        return
      }
      const permissions = Object.keys(applicationConfig.permissionsEnum).reduce(
        (acc, key) => ({
          ...acc,
          [key]: checkPermission(data as Permission[], applicationConfig.permissionsEnum![key]),
        }),
        {} as PermissionsData,
      )
      if (appState.modules) {
        appState.modules.permissions = permissions
      }
    })
  }, [])

  return { getPermissions }
}
