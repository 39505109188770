import { TwoFactorLoginData } from '@jume/core'
import { t } from '@jume/localization'
import { object, ObjectSchema, string } from 'yup'

export const twoFactorLoginSchema: ObjectSchema<TwoFactorLoginData> = object().shape({
  email: string()
    .email(() => t('validationErrors.incorrectEmailFormat'))
    .required(() => t('validationErrors.required')),
  password: string()
    .trim()
    .required(() => t('validationErrors.required')),
  code: string()
    .trim()
    .required(() => t('validationErrors.required')),
})
