import { FC, memo } from 'react'

import { CellContext } from '@tanstack/table-core'
import { ScorecardColumn } from 'prgm/scorecard/api'
import { useControlScenario } from 'prgm/scorecard/stores'

import { ControlScenarioSelect } from './ControlScenarioSelect'

interface Props {
  cellContext: CellContext<any>
  column: ScorecardColumn
}

export const ControlScenarioCell: FC<Props> = memo(({ cellContext, column }) => {
  const setValue = useControlScenario((state) => state.setValue)
  const columnDate = `${('00' + column.month).slice(-2)}${column.year}`
  const value = useControlScenario((state) => state.controlScenarioValues[cellContext.row.index]?.[columnDate])

  return (
    <ControlScenarioSelect
      column={column}
      rowId={cellContext.row.index}
      rowKey={columnDate}
      setValue={setValue}
      value={value}
    />
  )
})
