import { useState } from 'react'

import { AppState } from 'packages/app/appState'
import { AppModulesState, Project } from 'packages/app/modules'

export const useProjectInit = (appState: AppState): Pick<AppModulesState, 'project'> => {
  const [currentProject, setCurrentProject] = useState<Project | null>(null)
  const [disabledSelect, setDisabledSelect] = useState(false)

  return {
    project: Object.assign(appState.modules?.project || {}, {
      currentProject,
      setCurrentProject,
      disabledSelect,
      setDisabledSelect,
    }),
  }
}
