import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'packages/app'

export const Route = createFileRoute('/jume/approvals')({
  component: createPage({
    titleKey: 'titles.approvals',
    lazyImport: {
      fn: () => import('jume/workflow/components/ApprovalsBlock'),
      name: 'ApprovalsBlock',
    },
  }),
})
