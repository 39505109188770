import { UseFormReturn } from 'react-hook-form'

import { devtools } from '@jume/utils'
import { isEqual, pick } from 'lodash-es'
import { PrgmPromoForm } from 'prgm/createPromoForm/api'
import { create } from 'zustand'

// id - null только для сброса таблицы
type Form = PrgmPromoForm & { id?: number }

export interface UseCreateForm {
  form: Form
  setForm: (form: PrgmPromoForm) => void

  methods?: UseFormReturn<PrgmPromoForm>
  setFormMethods: (methods: UseFormReturn<PrgmPromoForm>) => void

  savedForm: Form
  setSavedForm: (form: PrgmPromoForm) => void

  calculateParamsChanged: boolean
  customerSplitParamsChanged: boolean
  resetParamsChanged: boolean
  resetVolumeBaseline: () => void

  edlpHiloMessage?: string
  setEdlpHiloMessage: (message?: string) => void

  clear: () => void
}

export const useCreatePromoForm = create<UseCreateForm>()(
  devtools(
    (set) => ({
      form: {},
      savedForm: {},
      calculateParamsChanged: false,
      customerSplitParamsChanged: false,
      resetParamsChanged: false,
      resetVolumeBaseline: () =>
        set(({ form, savedForm, methods }) => {
          methods?.setValue('volume', savedForm.volume)
          methods?.setValue('baseline', savedForm.baseline)

          return {
            form: {
              ...form,
              volume: savedForm.volume,
              baseline: savedForm.baseline,
            },
          }
        }),

      setSavedForm: (form) => {
        set({
          savedForm: {
            ...form,
            id: form.id || undefined,
          },
        })
      },
      setForm: (form) => {
        set(({ savedForm }) => {
          const keysToCompare = [
            'onInvoice',
            'offInvoice',
            'volume',
            'baseline',
            'shipStartDate',
            'shipEndDate',
            'chain',
            'ftSku',
            'mechanics',
          ]
          const customerSplitParams = ['ftSku', 'chain']
          const resetParams = ['volume', 'baseline']
          const calculateParamsChanged = !isEqual(pick(savedForm, keysToCompare), pick(form, keysToCompare))
          const customerSplitParamsChanged = !isEqual(
            pick(savedForm, customerSplitParams),
            pick(form, customerSplitParams),
          )
          const resetParamsChanged = !isEqual(pick(savedForm, resetParams), pick(form, resetParams))

          return {
            form: {
              ...form,
              id: form.id || undefined,
            },
            calculateParamsChanged,
            customerSplitParamsChanged,
            resetParamsChanged,
          }
        })
      },
      setFormMethods: (methods) => set({ methods }),
      clear: () =>
        set({
          form: {},
          savedForm: {},
          calculateParamsChanged: false,
          customerSplitParamsChanged: false,
          resetParamsChanged: false,
          edlpHiloMessage: undefined,
        }),
      setEdlpHiloMessage: (edlpHiloMessage) => set({ edlpHiloMessage }),
    }),
    { name: 'createPromoForm' },
  ),
)
