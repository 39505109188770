import { useState } from 'react'

import { FeedbackButton } from 'modules/feedback/components/FeedbackButton'
import { FeedbackModal } from 'modules/feedback/components/FeedbackModal'

export const Feedback = () => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <FeedbackButton onClick={() => setOpen(true)} />
      <FeedbackModal isOpened={open} onClose={() => setOpen(false)} />
    </>
  )
}
