import { Select, SelectTypes } from '@jume/ui'
import { HeaderContext } from '@tanstack/table-core'
import { ScorecardColumn } from 'prgm/scorecard/api'
import { useControlScenario } from 'prgm/scorecard/stores'

interface Props<TData, TValue> {
  headerContext: HeaderContext<TData, TValue>
  column: ScorecardColumn
}

export const ControlScenarioHeader = <TData, TValue>({ column }: Props<TData, TValue>) => {
  const setColumn = useControlScenario((state) => state.setColumn)
  const columnDate = `${('00' + column.month).slice(-2)}${column.year}`

  return (
    <Select
      labelInValue
      onChange={(value) => {
        const key = (value as any).value
        const option = column.options?.[key - 1]
        if (option) {
          setColumn(columnDate, option, key)
        }
      }}
      options={column.options.map((option, index) => ({
        label: option.name,
        value: index + 1,
      }))}
      placeholder={column.name}
      searchInDropdown
      showSearch
      type={SelectTypes.Cell}
      value={null}
    />
  )
}
