import { useContext } from 'react'

import { AppContext } from 'packages/app/providers'

export const useCurrentStream = () => {
  const appContext = useContext(AppContext)
  return {
    currentStream: appContext.modules?.stream.currentStream,
    setStream: appContext.modules?.stream.setStream,
  }
}
