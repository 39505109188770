import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'packages/app'

export const Route = createFileRoute('/opti/create-replen-scenario')({
  component: createPage({
    titleKey: 'titles.createReplenScenario',
    lazyImport: {
      fn: () => import('jume/replenScenarios/components/CreateReplenScenario'),
      name: 'CreateReplenScenario',
    },
  }),
})
