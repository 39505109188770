import { queryClient } from 'config/queryClient'
import { HierarchyQueries } from 'jume/hierarchyTree/queries/types'
import { useHierarchyTreeCurrentItems } from 'jume/hierarchyTree/store'
import { cacheForCallback } from 'utils/memoize'

export const useClearCashHierarchyTree = () => {
  const { hierarchyTree, hierarchyTreeArchive, clearTree, clearArchive } = useHierarchyTreeCurrentItems()

  const clearHierarchyTree = () => {
    if (Object.keys(hierarchyTree).length) {
      clearTree()
    }
    if (Object.keys(hierarchyTreeArchive).length) {
      clearArchive()
    }
    if (Object.keys(hierarchyTree).length || Object.keys(hierarchyTreeArchive).length) {
      queryClient.removeQueries({ queryKey: [HierarchyQueries.HierarchyTree] })
      cacheForCallback.delete('filterOptions')
    }
  }

  const clearCashHierarchyTree = () => {
    queryClient.removeQueries({ queryKey: [HierarchyQueries.HierarchyTree] })
  }

  return { clearHierarchyTree, clearCashHierarchyTree }
}
