import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'packages/app'

export const Route = createFileRoute('/demand/realignment')({
  component: createPage({
    titleKey: 'titles.realignment',
    lazyImport: {
      fn: () => import('demand/realignment/components/RealignmentPage'),
      name: 'RealignmentPage',
    },
  }),
})
