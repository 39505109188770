import {
  Smile1ActiveIcon,
  Smile1Icon,
  Smile2ActiveIcon,
  Smile2Icon,
  Smile3ActiveIcon,
  Smile3Icon,
  Smile4ActiveIcon,
  Smile4Icon,
  Smile5ActiveIcon,
  Smile5Icon,
} from '@jume/icons'

export const ratingIcons = [
  {
    icon: <Smile1Icon />,
    activeIcon: <Smile1ActiveIcon />,
    rating: 1,
  },
  {
    icon: <Smile2Icon />,
    activeIcon: <Smile2ActiveIcon />,
    rating: 2,
  },
  {
    icon: <Smile3Icon />,
    activeIcon: <Smile3ActiveIcon />,
    rating: 3,
  },
  {
    icon: <Smile4Icon />,
    activeIcon: <Smile4ActiveIcon />,
    rating: 4,
  },
  {
    icon: <Smile5Icon />,
    activeIcon: <Smile5ActiveIcon />,
    rating: 5,
  },
]
