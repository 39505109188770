import { ApiError } from '@jume/api'
import { PromoCreateDataSourceOptions, PromoDataSourceInfo } from 'opti/promoDataSources/api/interfaces'

export enum PromoScenarioTabsCodes {
  Input = 'Input',
  ParamsForRun = 'ParamsForRun',
  Output = 'Output',
}

export enum PromoDataSourceTypes {
  CombinePr = 'combinePr',
  CustomerMd = 'customerMd',
  Gps = 'gps',
  PromoLibrary = 'promoLibrary',
  PromoUpDownSizes = 'promoUpDownSizes',
  ProductMd = 'productMd',
  CombineChains = 'combineChains',
  DistrMappings = 'distrMappings',
}

export enum InputTypes {
  CombineChains = 'promo combine chains',
  CombinePr = 'promo combine products',
  CustomerMd = 'promo customer md',
  DistrMappings = 'promo distr mapping',
  Gps = 'promo gps',
  ProductMd = 'promo product md',
  PromoLib = 'promo lib',
  PromoUpDownSizes = 'promo up down size',
}

export enum UploadStatuses {
  Failed = 'failed',
  Success = 'success',
  Loading = 'loading',
  Idle = 'idle',
}

export type UseDownloadTemplate = (enabled: boolean) => {
  downloadTemplate: () => Promise<void>
  errorTemplateLink: unknown
  isLoadingTemplateLink: boolean
  isSuccessTemplateLink: boolean
}

export type PromoCreateDataSource = ({ data, params }: PromoCreateDataSourceOptions) => void

export type UseCreateDataSource = (scenarioId: number) => {
  create: ({ data, params }: PromoCreateDataSourceOptions) => void
  error: ApiError | null
  isLoading: boolean
}

export type UseDataSourceStatus = (scenarioId: number) => {
  isLoading: boolean
  isUploaded: boolean
  info: PromoDataSourceInfo
}
