import { ReactNode } from 'react'

import { Get } from 'interfaces/tanstackQuery.interfaces'
import { Mutate, StoreApi } from 'zustand'

export interface ValidationError<Tab extends string = string, Key extends string = string> {
  index?: number
  group?: string | number
  tab: Tab
  key?: Key
  message: ReactNode | null
  meta?: any
}

export interface UseErrors<Error extends ValidationError = ValidationError> {
  errors: Error[]
  clear: () => void
  setErrors: (errors: Error[]) => void
  removeError: (
    tab: Error['tab'],
    group: string | number | undefined,
    index: number | undefined,
    key: Error['key'],
  ) => void
  removeByIndex: (tab: Error['tab'], index: number | undefined) => void
  removeByIndexAndShift: (tab: Error['tab'], index: number | undefined) => void
  removeByGroup: (tab: Error['tab'], group: string | number | undefined) => void
  removeByKey: (tab: Error['tab'], key: string | undefined) => void
  removeByTab: (tab: Error['tab']) => void
  findError: (
    tab: Error['tab'],
    group: string | number | undefined,
    index: number | undefined,
    key: Error['key'],
  ) => { error: Error | undefined; removeError: () => void }
  findErrors: (tab: Error['tab'], group: string | number | undefined, key: Error['key']) => Error[]
}

export const generateErrorsState = <Error extends ValidationError = ValidationError>(
  set: Get<Mutate<StoreApi<UseErrors<Error>>, []>, 'setState', never>,
  get: () => UseErrors<Error>,
): UseErrors<Error> => ({
  errors: [],
  clear: () => set({ errors: [] }),
  setErrors: (errors) => set({ errors }),
  removeError: (tab, group, index, key) =>
    set(({ errors }) => ({
      errors: errors
        .filter((item) => item.index !== index || item.group !== group || item.key !== key || item.tab !== tab)
        .map((item) => ({
          ...item,
          index:
            typeof item.index !== 'undefined' && typeof index !== 'undefined' && item.index >= index && item.key === key
              ? item.index - 1
              : item.index,
        })),
    })),
  removeByIndex: (tab, index) =>
    set(({ errors }) => ({
      errors: errors.filter((item) => item.index !== index || item.tab !== tab),
    })),
  removeByIndexAndShift: (tab, index) =>
    set(({ errors }) => ({
      errors: errors
        .filter((item) => item.index !== index || item.tab !== tab)
        .map((item) => ({
          ...item,
          index:
            typeof item.index !== 'undefined' && typeof index !== 'undefined' && item.index >= index
              ? item.index - 1
              : item.index,
        })),
    })),
  removeByGroup: (tab, group) =>
    set(({ errors }) => ({ errors: errors.filter((item) => item.group !== group || item.tab !== tab) })),
  removeByKey: (tab, key) =>
    set(({ errors }) => ({ errors: errors.filter((item) => item.key !== key || item.tab !== tab) })),
  removeByTab: (tab) => set(({ errors }) => ({ errors: errors.filter((item) => item.tab !== tab) })),
  findError: (tab, group, index, key) => {
    const { errors, removeError } = get()
    return {
      error: errors.find(
        (item) => item.index === index && item.group === group && item.tab === tab && item.key === key,
      ),
      removeError: () => removeError(tab, group, index, key),
    }
  },
  findErrors: (tab, group, key) => {
    const { errors } = get()
    return errors.filter(
      (item) =>
        (group === undefined || item.group === group) &&
        (tab === undefined || item.tab === tab) &&
        (key === undefined || item.key === key),
    )
  },
})
