import { devtools } from '@jume/utils'
import { create } from 'zustand'

import { UseSavedManualCorrections } from './types'

export const useSavedManualCorrections = create<UseSavedManualCorrections>()(
  devtools(
    (set) => ({
      manualCorrections: {},
      clear: () => set({ manualCorrections: {} }),
    }),
    {
      store: 'savedManualCorrections',
    },
  ),
)
