import { deleteEmpty, deleteNullableInArray, devtools, removeByIndex } from '@jume/utils'
import { TransitionTableRow, TransitionUpdateElement } from 'jume/transitionTable/api'
import { isEqual } from 'lodash-es'
import { uid } from 'uid'
import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'

import { UseUpdateTransitionTable } from './types'

const filterFn = (item: TransitionUpdateElement, initialRow?: TransitionTableRow) =>
  !isEqual(
    {
      id: item.id,
      isDeleted: !!item.isDeleted,
      statusId: item.statusId ?? initialRow?.newMasterDataElement?.manualStatus?.id,
      date: item.date ?? initialRow?.date,
    },
    {
      id: initialRow?.id,
      isDeleted: false,
      isCancel: false,
      statusId: initialRow?.newMasterDataElement?.manualStatus?.id,
      date: initialRow?.date,
    },
  )

const filterEquals = (updated: UseUpdateTransitionTable['updated'], initialRow?: TransitionTableRow) =>
  initialRow ? updated.filter((item) => filterFn(item, initialRow)) : updated

const filterEqualsArray = (updated: UseUpdateTransitionTable['updated'], initialRows?: TransitionTableRow[]) =>
  initialRows?.length
    ? updated.filter((item) => {
        const initialRow = initialRows.find((row) => item.id === row.id)
        return filterFn(item, initialRow)
      })
    : updated

export const useUpdateTransitionTable = create<UseUpdateTransitionTable>()(
  devtools(
    subscribeWithSelector((set) => ({
      updated: [],
      created: [],
      lastSavedErrorData: {
        updated: [],
        created: [],
      },
      createRow: () =>
        set(({ created }) => ({
          created: [
            ...created,
            {
              idCreated: uid(),
              date: '',
            },
          ],
        })),
      setLastSavedErrorData: (lastSavedErrorData) => set(() => ({ lastSavedErrorData })),
      updateMasterDataNewElement: (masterDataElement, id, type) => {
        if (!masterDataElement && type === 'created') {
          set(({ created }) => {
            const rowIndex = created.findIndex((item) => item.idCreated === id)
            if (rowIndex > -1) {
              delete created[rowIndex].masterDataNewElement
            }
            return { created }
          })
          return
        }
        if (masterDataElement && type === 'created') {
          set(({ created }) => ({
            created: created.map((item) =>
              item.idCreated === id
                ? {
                    ...item,
                    masterDataNewElement: masterDataElement,
                  }
                : item,
            ),
          }))
        }
      },
      updateDate: (date, id, type) => {
        if (!date && type === 'updated') {
          set(({ updated }) => {
            const rowIndex = updated.findIndex((item) => item.id === id)
            if (rowIndex > -1) {
              delete updated[rowIndex].date
              if (!Object.keys(deleteEmpty({ ...updated[rowIndex], id: null })).length) {
                updated.splice(rowIndex, 1)
              }
            }
            return { updated }
          })
          return
        }
        if (!date && type === 'created') {
          set(({ created }) => {
            const rowIndex = created.findIndex((item) => item.idCreated === id)
            if (rowIndex > -1) {
              created[rowIndex].date = ''
              if (!Object.keys(deleteEmpty({ ...created[rowIndex], id: null, idCreated: null })).length) {
                created.splice(rowIndex, 1)
              }
            }
            return { created }
          })
          return
        }
        if (date && type === 'updated' && id) {
          set(({ updated }) => {
            const row = updated.find((item) => item.id === id)
            if (row) {
              row.date = date
            } else {
              updated.push({ id: id as number, date })
            }
            return { updated }
          })
        }
        if (date && type === 'created') {
          set(({ created }) => ({
            created: created.map((item) =>
              item.idCreated === id
                ? {
                    ...item,
                    date,
                  }
                : item,
            ),
          }))
        }
      },
      updateMasterDataOldElement: (masterDataElement, id, type) => {
        if (!masterDataElement && type === 'created') {
          set(({ created }) => {
            const rowIndex = created.findIndex((item) => item.idCreated === id)
            if (rowIndex > -1) {
              delete created[rowIndex].masterDataOldElement
            }
            return { created }
          })
          return
        }
        if (masterDataElement && type === 'created') {
          set(({ created }) => ({
            created: created.map((item) =>
              item.idCreated === id
                ? {
                    ...item,
                    masterDataOldElement: masterDataElement,
                  }
                : item,
            ),
          }))
        }
      },
      updateStatus: (statusId, id, type) => {
        if (!statusId && type === 'updated') {
          set(({ updated }) => {
            const rowIndex = updated.findIndex((item) => item.id === id)
            if (rowIndex > -1) {
              delete updated[rowIndex].statusId
              if (!Object.keys(deleteEmpty({ ...updated[rowIndex], id: null })).length) {
                updated.splice(rowIndex, 1)
              }
            }
            return { updated }
          })
          return
        }
        if (statusId && type === 'updated' && id) {
          set(({ updated }) => {
            const row = updated.find((item) => item.id === id)
            if (row) {
              row.statusId = statusId
            } else {
              updated.push({ id: id as number, statusId })
            }
            return { updated }
          })
        }
      },
      updateStatuses: (statusId, rows) => {
        const updatedRows = rows.filter((item) => item.type === 'updated').map((item) => item.id)
        if (!statusId) {
          set(({ updated }) => ({
            updated: deleteNullableInArray(
              updated.map((item) => {
                if (updatedRows.includes(item.id)) {
                  delete item.statusId
                  if (!Object.keys(deleteEmpty({ ...item, id: null })).length) {
                    return null
                  }
                }
                return item
              }),
            ),
          }))
        } else {
          set(({ updated }) => {
            const newUpdatedIds = updatedRows.filter(
              (updatedId) => !updated.map((item) => item.id).includes(updatedId as number),
            )
            return {
              updated: [
                ...updated.map((item) => {
                  if (updatedRows.includes(item.id)) {
                    item.statusId = statusId
                  }
                  return item
                }),
                ...newUpdatedIds.map((updatedId) => ({ id: updatedId as number, statusId })),
              ],
            }
          })
        }
      },
      removeNew: (index) => set(({ created }) => ({ created: removeByIndex(created, index) })),
      clearDeleted: () => set(({ updated }) => ({ updated: updated.filter((item) => !item.isDeleted) })),
      clear: () => set({ updated: [], created: [], lastSavedErrorData: { updated: [], created: [] } }),
      setDeleted: (id, isDeleted, initialRow) =>
        set(({ updated }) => {
          const row = updated.find((item) => item.id === id)
          if (row) {
            row.isDeleted = isDeleted
          } else {
            updated.push({ id, isDeleted })
          }
          if (row?.isDeleted === false) {
            delete row.isDeleted
          }
          return { updated: filterEquals(updated, initialRow) }
        }),
      setDeletedIds: (ids, initialRows) =>
        set(({ updated }) => {
          ids.forEach((id) => {
            const row = updated.find((item) => item.id === id)
            if (row) {
              row.isDeleted = true
            } else {
              updated.push({ id, isDeleted: true })
            }
          })
          return { updated: filterEqualsArray(updated, initialRows) }
        }),
      lastSavedError: null,
      setLastSavedError: (lastSavedError) => set(() => ({ lastSavedError })),
    })),
    {
      store: 'transitionTable',
    },
  ),
)
