import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'packages/app'

export const Route = createFileRoute('/deploy/data-hub/')({
  component: createPage({
    titleKey: 'titles.deploySessionManagement',
    lazyImport: {
      fn: () => import('deploy/pages/session/Session'),
      name: 'Session',
    },
  }),
})
