import { ColumnDef } from '@tanstack/table-core'
import { ScorecardColumnOption, ScorecardTable, ScorecardTableRow } from 'prgm/scorecard/api'
import { ControlScenarioCell } from 'prgm/scorecard/components/ControlScenarioTable/ControlScenarioCell'
import { ControlScenarioHeader } from 'prgm/scorecard/components/ControlScenarioTable/ControlScenarioHeader'
import { getDateFromKey } from 'prgm/scorecard/helpers'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export interface UseControlScenario {
  controlScenario: ScorecardTableRow[]
  controlScenarioValues: Record<string, number | undefined>[]
  columnsLeft: ColumnDef<any, any>[]
  columns: ColumnDef<any, any>[]
  setValue: (rowId: number, key: string, option: ScorecardColumnOption, value: number) => void
  setControlScenario: (scenario: ScorecardTable) => void
  setColumn: (column: string, option: ScorecardColumnOption, value: number) => void
  clear: () => void
}

export const useControlScenario = create<UseControlScenario>()(
  immer((set) => ({
    controlScenario: [],
    controlScenarioValues: [],
    columnsLeft: [],
    columns: [],
    setValue: (rowId, key, option, value) =>
      set(({ controlScenario, controlScenarioValues }) => {
        controlScenario[rowId][key] = option
        controlScenarioValues[rowId][key] = value
      }),
    setControlScenario: (scenario) =>
      set(() => {
        const columns = scenario.columns.map(
          (column) =>
            ({
              accessorKey: column.name,
              id: column.name,
              header: (headerContext) => <ControlScenarioHeader column={column} headerContext={headerContext} />,
              cell: (cellContext) => <ControlScenarioCell cellContext={cellContext} column={column} />,
            }) as ColumnDef<any, any>,
        )

        return {
          controlScenario: scenario.rows.map((row) => {
            const tableRow: ScorecardTableRow = { channel: row.channel, channelId: row.channelId }
            Object.keys(row)
              .filter((key) => !['channel', 'channelId'].includes(key))
              .forEach((key) => {
                const { year, month } = getDateFromKey(key)
                const foundColumn = scenario.columns.find((column) => column.month === month && column.year === year)
                if (foundColumn) {
                  tableRow[key] = foundColumn.options.find((option) => option.value === row[key])
                }
              })

            return tableRow
          }),
          controlScenarioValues: scenario.rows.map((row) => {
            const tableRow: any = {}
            Object.keys(row)
              .filter((key) => !['channel', 'channelId'].includes(key))
              .forEach((key) => {
                const { year, month } = getDateFromKey(key)
                const foundColumn = scenario.columns.find((column) => column.month === month && column.year === year)
                if (foundColumn) {
                  const value = foundColumn.options.findIndex((option) => option.value === row[key])
                  if (value !== -1) {
                    tableRow[key] = value + 1
                  }
                }
              })

            return tableRow
          }),
          columns,
          columnsLeft: [{ id: 'channel', accessorKey: 'channel', header: 'Name' }],
        }
      }),
    setColumn: (column, option, value) =>
      set(({ controlScenario, controlScenarioValues }) => {
        controlScenario.forEach((row) => (row[column] = option))
        controlScenarioValues.forEach((row) => (row[column] = value))
      }),
    clear: () =>
      set({
        controlScenario: [],
        controlScenarioValues: [],
        columnsLeft: [],
        columns: [],
      }),
  })),
)
