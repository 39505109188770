import { devtools } from '@jume/utils'
import { create } from 'zustand'

import { UseSelectedICTable } from './types'

export const useSelectedICTable = create<UseSelectedICTable>()(
  devtools(
    (set) => ({
      selectedIds: [],
      setSelectedIds: (state) => {
        set((prev) => {
          const selectedIds = typeof state === 'function' ? state(prev.selectedIds) : state
          return { selectedIds }
        })
      },
      clear: () => set({ selectedIds: [] }),
    }),
    {
      store: 'selectedICTable',
    },
  ),
)
