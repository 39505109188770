import { useQueryClient } from '@jume/query'
import { ICQueries } from 'jume/innovationCalculator/queries/types'
import { IMQueries } from 'jume/innovationManagement/queries/types'
import { PMQueries } from 'jume/promoManagement/queries/types'

export const useClearInnovationDashboard = () => {
  const queryClient = useQueryClient()

  const clearInnovationDashboard = () => {
    queryClient.removeQueries({ queryKey: [IMQueries.IMTable] })
    queryClient.removeQueries({ queryKey: [PMQueries.PMTable] })
    queryClient.removeQueries({ queryKey: [ICQueries.ICTable] })
    queryClient.removeQueries({ queryKey: [ICQueries.SearchICElement] })
  }

  return { clearInnovationDashboard }
}
