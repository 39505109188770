import { systemLocals } from '@jume/localization'
import { milkLocals } from 'milkPlanning'
import { dataHubLocals } from 'modules/dataHub/dataHubLocals'

export const keySetsEn = [
  ...systemLocals.en,
  ...dataHubLocals.en,
  ...milkLocals.en,
  () => import('./titles.json'),
  () => import('./menu.json'),
  () => import('./dataSources.json'),
  () => import('./forecast.json'),
  () => import('./projects.json'),
  () => import('./blockA.json'),
  () => import('./blockB.json'),
  () => import('./charts.json'),
  () => import('./kpi.json'),
  () => import('./pulse.json'),
  () => import('./statusDashboard.json'),
  () => import('./masterData.json'),
  () => import('./promoScenario.json'),
  () => import('./promoScenarioOutput.json'),
  () => import('./validationErrors.json'),
  () => import('./errorsNoData.json'),
  () => import('./errors.json'),
  () => import('./delistingTable.json'),
  () => import('./innovationTable.json'),
  () => import('./promoTable.json'),
  () => import('./statusTable.json'),
  () => import('./transitionTable.json'),
  () => import('./promoDataSource.json'),
  () => import('./innovationDashboard.json'),
  () => import('./recommendations.json'),
  () => import('./innovationCalculator.json'),
  () => import('./wsErrors.json'),
  () => import('./editMode.json'),
  () => import('./workflow.json'),
  () => import('./replenScenario.json'),
  () => import('./rtmScenario.json'),
  () => import('./rtmDataSource.json'),
  () => import('./rtmScenarioOutput.json'),
  () => import('./tetrisScenario.json'),
  () => import('./tetrisDataSource.json'),
  () => import('./scenarioShared.json'),
  () => import('./cfrScenario.json'),
  () => import('./cfrDataSource.json'),
  () => import('./cfrScenarioOutput.json'),
  () => import('./demand.selections.json'),
  () => import('./demand.forecast.json'),
  () => import('./demand.masterData.json'),
  () => import('./demand.forecastLoad.json'),
  () => import('./demand.dataHandling.json'),
  () => import('./demand.domino.json'),
  () => import('./demand.correctionLog.json'),
  () => import('./demand.recommendations.json'),
  () => import('./demand.realignment.json'),
  () => import('./demand.jobLock.json'),
  () => import('./prgmCreate.json'),
  () => import('./prgm.json'),
  () => import('./prgm.skuTable.json'),
  () => import('./prgm.promoActions.json'),
  () => import('./prgm.searchPromo.json'),
  () => import('./prgm.approvePromo.json'),
  () => import('./prgm.pulse.json'),
  () => import('./prgm.dataHub.json'),
  () => import('./prgm.reports.json'),
  () => import('./jobs.json'),
  () => import('./jobCreate.json'),
  () => import('./deploy.json'),
  () => import('./deploy.dataHub.json'),
  () => import('./deploy.stockAllocation.json'),
  () => import('./session.json'),
  () => import('./attributes.json'),
  () => import('./kf.json'),
  () => import('./filters.json'),
  () => import('./deploy.transportOptimization.json'),
  () => import('./ppds.json'),
  () => import('./ppds.materials.json'),
  () => import('./ppds.materialsFamily.json'),
  () => import('./ppds.resourceFamilies.json'),
  () => import('./feedback.json'),
  () => import('./deploy.pped.json'),
  () => import('./prgm.promoChangeHistory.json'),
  () => import('./prgm.scorecard.json'),
  () => import('./prgm.dataHealth.json'),
  () => import('./prgm.phasing.json'),
]
