import { create } from 'zustand'

import { UseRecommendationDoneItems } from './types'

export const useRecommendationDoneItems = create<UseRecommendationDoneItems>()((set) => ({
  selectedIds: [],
  setSelectedIds: (state) => {
    set((prev) => {
      const selectedIds = typeof state === 'function' ? state(prev.selectedIds) : state
      return { selectedIds }
    })
  },
}))
