import { Streams } from 'constants/streams'
import { demandForecastLoadModule } from 'demand/forecastLoad/demandForecastLoadModule'
import { demandMasterDataModule } from 'demand/masterData/demandMasterDataModule'
import { demandRealignmentModule } from 'demand/realignment/DemandRealignmentModule'
import { demandRecommendationsModule } from 'demand/recommendations/demandRecommendationsModule'
import { demandSelectionsModule } from 'demand/selections/demandSelectionsModule'
import { jobsModule } from 'modules/jobs/jobsModule'
import { StreamConnector } from 'packages/app'

const demandModules = {
  selections: demandSelectionsModule(),
  forecastLoad: demandForecastLoadModule(),
  masterData: demandMasterDataModule(),
  realignment: demandRealignmentModule(),
  jobs: jobsModule(undefined, { streamName: Streams.Demand }),
  recommendations: demandRecommendationsModule(),
}

export const connectDemandStream: StreamConnector = () => ({
  name: Streams.Demand,
  startPage: `/${Streams.Demand}/selections`,
  modules: demandModules,
  menu: (menuBuilder) => {
    menuBuilder.addItem(demandModules.selections.menuItems.selections)
    menuBuilder.addItem(demandModules.forecastLoad.menuItems.forecastLoad)
    menuBuilder.addItem(demandModules.masterData.menuItems.masterData)
    menuBuilder.addItem(demandModules.realignment.menuItems.realignment)
    menuBuilder.addItem(demandModules.jobs.menuItems.jobs)
    menuBuilder.addItem(demandModules.recommendations.menuItems.recommendations)
  },
})
