import { t } from '@jume/localization'
import { Streams } from 'constants/streams'
import { ModuleConnector } from 'packages/app'

export const prgmPulseModule: ModuleConnector = (options) => ({
  ...options,
  menuItems: {
    pulse: {
      label: () => t('prgm.navMenu.pulse'),
      href: `/${Streams.Prgm}/pulse`,
      dependence: (appContext) =>
        appContext.modules.permissions?.readPulseDb || appContext.modules.permissions?.editPulseDb,
    },
  },
})
