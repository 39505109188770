import { createEffect } from '@jume/core'
import { Streams } from 'constants/streams'
import { useClearAppCashOpti } from 'opti/shared/handlers'
import { useProjectType } from 'opti/shared/handlers/useProjectType'
import { optiScenariosModule } from 'opti/shared/optiScenariosModule'
import { Project, StreamConnector } from 'packages/app'

const optiModules = {
  scenarios: optiScenariosModule(),
}

export const connectOptiStream: StreamConnector = () => ({
  name: Streams.Opti,
  startPage: `/${Streams.Opti}`,
  projects: {
    enabled: true,
    onSwitchProject: createEffect({
      hook: useClearAppCashOpti,
      fnName: 'clearAppCashOpti',
    }),
    onGetProject: createEffect<[Project]>({
      hook: useProjectType,
    }),
  },
  modules: optiModules,
  menu: (menuBuilder) => {
    menuBuilder.addItem(optiModules.scenarios.menuItems.promoOptimizer)
    menuBuilder.addItem(optiModules.scenarios.menuItems.createPromoScenario)
    menuBuilder.addItem(optiModules.scenarios.menuItems.rtmOptimizer)
    menuBuilder.addItem(optiModules.scenarios.menuItems.createRtmScenario)
    menuBuilder.addItem(optiModules.scenarios.menuItems.tetrisOptimizer)
    menuBuilder.addItem(optiModules.scenarios.menuItems.createTetrisScenario)
    menuBuilder.addItem(optiModules.scenarios.menuItems.cfrOptimizer)
    menuBuilder.addItem(optiModules.scenarios.menuItems.createCfrScenario)
  },
})
