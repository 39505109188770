import { useMutationState as useMutationStateBase } from '@tanstack/react-query'

export const useMutationState = (key: string) => {
  const mutations = useMutationStateBase({
    filters: { mutationKey: [key] },
    select: (mutation) => ({ status: mutation.state.status, error: mutation.state.error }),
  })

  return {
    error: mutations.find((mutation) => !!mutation.error)?.error,
    isLoading: mutations.find((mutation) => mutation.status === 'pending')?.status === 'pending',
    isSuccess: mutations.find((mutation) => mutation.status === 'success')?.status === 'success',
  }
}
