import storage from 'config/storage'
import { useEditComment } from 'prgm/comments/store/useEditComment'
import { useCustomerTable } from 'prgm/customerSplit/store'
import { usePnlTable } from 'prgm/plTable/store'
import { useClearScorecard } from 'prgm/scorecard/handler'
import { useSearchFilters } from 'prgm/searchPromo/store'
import { useSkuTable } from 'prgm/skuTable/store'

export const useClearAppCashPrgm = () => {
  const searchFiltersClear = useSearchFilters((state) => state.clear)
  const clearPnlTable = usePnlTable((state) => state.clearPnlTable)
  const setOpenPnlTable = usePnlTable((state) => state.setOpen)
  const clearCustomerTable = useCustomerTable((state) => state.clear)
  const setOpenCustomerTable = useCustomerTable((state) => state.setOpen)
  const clearEditComment = useEditComment((state) => state.clear)
  const clearSkuSplit = useSkuTable((state) => state.clear)
  const setOpenSkuTable = useSkuTable((state) => state.setOpen)
  const { clearScorecard } = useClearScorecard()

  const clearAppCashPrgm = () => {
    searchFiltersClear()
    clearPnlTable()
    clearCustomerTable()
    clearEditComment()
    clearSkuSplit()
    setOpenPnlTable(true)
    setOpenCustomerTable(true)
    setOpenSkuTable(true)
    storage.removeItem('prgm.pulseTable')
    storage.removeItem('prgm.approveTable')
    storage.removeItem('prgm.searchTable')
    clearScorecard()
  }

  return {
    clearAppCashPrgm,
  }
}
