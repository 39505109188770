import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'packages/app'

export const Route = createFileRoute('/prgm/scorecard')({
  component: createPage({
    titleKey: 'titles.prgmScorecard',
    lazyImport: {
      fn: () => import('prgm/scorecard/components/ScorecardBlock'),
      name: 'ScorecardBlock',
    },
  }),
})
