import { useCallback, useContext, useEffect, useRef, useState } from 'react'

import { useBlocker } from '@tanstack/react-router'
import { AppContext } from 'packages/app'
import { useLogout } from 'packages/app/modules/logout'

import { useIsDirty } from './useIsDirty'

export const useUnsavedChangesBlocker = () => {
  const [isOpenedModal, setIsOpenedModal] = useState(false)
  const isLogout = useRef(false)
  const onOkModal = useRef<() => void>(() => {})
  const onCancelModal = useRef<() => void>(() => {})
  const { isDirty, message, onOk, onCancel, clearUnsavedChanges } = useIsDirty()
  const appContext = useContext(AppContext)
  const { logout } = useLogout()

  const confirm = useCallback(() => {
    setIsOpenedModal(false)
    onOk?.()
    if (isLogout.current) {
      clearUnsavedChanges()
      requestAnimationFrame(logout)
    }
  }, [logout])

  const cancel = useCallback(() => {
    setIsOpenedModal(false)
    onCancel?.()
  }, [])

  useBlocker({
    blockerFn: () =>
      new Promise((resolve) => {
        setIsOpenedModal(true)
        onOkModal.current = () => {
          resolve(true)
          confirm()
        }
        onCancelModal.current = () => {
          resolve(false)
          cancel()
        }
      }),
    condition: isDirty,
  })

  useEffect(() => {
    appContext.auth.openLogoutUnsavedChanges = () => {
      setIsOpenedModal(true)
      isLogout.current = true
      onOkModal.current = confirm
      onCancelModal.current = cancel
    }
  }, [])

  return {
    onOk: onOkModal.current,
    onCancel: onCancelModal.current,
    isOpenedModal,
    message,
  }
}
