export const shallowCompareArrays = (array1: any[], array2: any[]) => {
  if (!Array.isArray(array1) || !Array.isArray(array2)) {
    return false
  }

  if (array1.length !== array2.length) {
    return false
  }

  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false
    }
  }

  return true
}
