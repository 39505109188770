import { t } from '@jume/localization'
import { Streams } from 'constants/streams'
import { ModuleConnector } from 'packages/app'

export const demandSelectionsModule: ModuleConnector = (options) => ({
  ...options,
  menuItems: {
    selections: {
      label: () => t('menu.selections'),
      href: `/${Streams.Demand}/selections`,
      dependence: (appContext) => appContext.modules.permissions?.viewSelectionsDashboard,
    },
  },
})
