import { isProtectedPageWithoutMain } from '@jume/core'
import { useLocation } from '@tanstack/react-router'
import { AuthLayout, MainLayout } from 'packages/app/layout'

export const useLayout = () => {
  const { pathname } = useLocation()

  const Layout = isProtectedPageWithoutMain(pathname) ? MainLayout : AuthLayout

  return { Layout }
}
