import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'packages/app'

export const Route = createFileRoute('/opti/create-tetris-scenario')({
  component: createPage({
    titleKey: 'titles.createTetrisScenario',
    lazyImport: {
      fn: () => import('opti/tetrisScenario/components/CreateTetrisScenario'),
      name: 'CreateTetrisScenario',
    },
  }),
})
