import { useInfiniteQuery } from '@jume/query'
import { flatten } from 'lodash-es'
import { useProfile } from 'packages/app/modules/profile'
import { projectsApi } from 'packages/app/modules/projects/api'

import { ProjectsQueries } from './types'

export const useSearchProject = (search?: string) => {
  const { profile } = useProfile()

  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [ProjectsQueries.SearchProject, search],
    queryFn: ({ pageParam }) => projectsApi.searchProject(search, { page: pageParam }),
    enabled: !!profile,
  })

  return {
    projects: flatten(data?.pages.map((page) => page.results) || []),
    hasNextPage,
    isLoading: isLoading || isFetchingNextPage,
    fetchNextPage,
  }
}
