import { devtools } from '@jume/utils'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { UseUnsavedManualCorrections } from './types'

export const useUnsavedManualCorrections = create<UseUnsavedManualCorrections>()(
  devtools(
    persist(
      (set) => ({
        manualCorrections: {},
        clear: () => set({ manualCorrections: {} }),
      }),
      {
        name: 'unsavedManualCorrections',
      },
    ),
    {
      store: 'unsavedManualCorrections',
    },
  ),
)
