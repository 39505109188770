import { useCalculateScenarios } from 'prgm/scorecard/queries'
import {
  useControlCalculate,
  useControlScenario,
  useScorecardSettings,
  useTargetCalculate,
  useTargetScenario,
} from 'prgm/scorecard/stores'

export const useClearScorecard = () => {
  const clearSettings = useScorecardSettings((state) => state.clear)
  const clearTargetCalculate = useTargetCalculate((state) => state.clear)
  const clearControlCalculate = useControlCalculate((state) => state.clear)
  const clearTargetScenario = useTargetScenario((state) => state.clear)
  const clearControlScenario = useControlScenario((state) => state.clear)
  const { reset } = useCalculateScenarios()

  const clearScorecard = () => {
    clearSettings()
    clearTargetCalculate()
    clearControlCalculate()
    clearTargetScenario()
    clearControlScenario()
    reset()
  }

  return {
    clearScorecard,
  }
}
