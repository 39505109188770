import { useEffect } from 'react'

import { useLocation } from '@tanstack/react-router'
import { useSession } from 'packages/app/modules/session/store'
import { useCurrentStream } from 'packages/app/modules/streams'

export const useSaveSessionPage = () => {
  const session = useSession.getState()
  const { pathname } = useLocation()
  const { currentStream } = useCurrentStream()

  useEffect(() => {
    if (currentStream?.session?.enabled) {
      session.setPage(pathname)
    } else if (currentStream) {
      session.clear()
    }
  }, [pathname, currentStream?.name])
}
