import { createContext, ReactNode } from 'react'

type DemandPages = 'realignment' | 'forecast' | 'forecastDataUpload'

interface DemandContextInterface {
  locked: {
    Component: ReactNode
    pages: Partial<Record<DemandPages, true | ReactNode>>
    isLocked: boolean
  }
}
interface OnlyDefault {
  locked: {
    getComponentByPage: (page: keyof DemandContextInterface['locked']['pages']) => ReactNode
  }
}

type DemandContextProps = DemandContextInterface & OnlyDefault

const defaultDemandContextValue: DemandContextProps = {
  locked: {
    Component: null,
    pages: {},
    isLocked: false,
    getComponentByPage(page) {
      return this.pages[page] === true ? this.Component : this.pages[page]
    },
  },
}

export const DemandContext = createContext<DemandContextProps>(defaultDemandContextValue)

export interface DemandProviderProps extends Partial<DemandContextInterface> {
  children?: ReactNode
}

export const DemandProvider = ({ children, ...props }: DemandProviderProps): ReactNode => (
  <DemandContext.Provider
    value={
      {
        ...defaultDemandContextValue,
        ...props,
        locked: {
          ...props.locked,
          getComponentByPage: defaultDemandContextValue.locked.getComponentByPage.bind(props.locked),
        },
      } as any
    }
  >
    {children}
  </DemandContext.Provider>
)
