import { devtools } from '@jume/utils'
import { create } from 'zustand'

import { UseJob } from './types'

export const useJob = create<UseJob>()(
  devtools(
    (set) => ({
      job: undefined,
      setJob: (job) => set({ job }),
      clear: () => set({ job: undefined }),
    }),
    {
      store: 'jobValues',
    },
  ),
)
