import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'packages/app'

export const Route = createFileRoute('/prgm/data-health')({
  component: createPage({
    titleKey: 'titles.prgm.dataHealth',
    lazyImport: {
      fn: () => import('prgm/dataHealth/components/DataHealthBlock'),
      name: 'DataHealthBlock',
    },
  }),
})
