import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'packages/app'

export const Route = createFileRoute('/prgm/data-hub')({
  component: createPage({
    titleKey: 'titles.prgmDataHub',
    lazyImport: {
      fn: () => import('prgm/dataHub/components/DataHubBlock'),
      name: 'DataHubBlock',
    },
  }),
})
