import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'packages/app'

export const Route = createFileRoute('/jume/master-data/$hierarchyId')({
  component: createPage({
    titleKey: 'titles.masterDataHierarchy',
    lazyImport: {
      fn: () => import('jume/masterData/components/MasterDataBlock'),
      name: 'RealignmentPage',
    },
  }),
})
