import { useContext } from 'react'

import { getEffects } from '@jume/core'
import { useMutation } from '@tanstack/react-query'
import { projectsApi } from 'packages/app/modules/projects/api'
import { AppContext } from 'packages/app/providers'

export const useSwitchProject = () => {
  const appContext = useContext(AppContext)
  const switchProjectEffects = getEffects(appContext.modules.stream.currentStream?.projects?.onSwitchProject)

  const { mutate, isPending } = useMutation({
    mutationFn: async (newProjectId: number) => projectsApi.updateCurrentProject({ activeProjectId: newProjectId }),
    onSuccess: (profile, newProjectId) => {
      switchProjectEffects.call(newProjectId)
      appContext.modules.profile = profile
    },
  })

  return {
    switchProject: (newProjectId: number) => mutate(newProjectId),
    isLoading: isPending,
  }
}
