import { FC } from 'react'

import { createRouter, RouterProvider } from '@tanstack/react-router'

import { routeTree } from './routeTree.gen'

export const router = createRouter({ routeTree })

const App: FC = () => <RouterProvider router={router} />

export default App
