import { FC, useContext } from 'react'

import { Select } from '@jume/ui'
import { useProfile } from 'packages/app/modules/profile'
import { titleStreamToCode } from 'packages/app/modules/streams/titleStreamToCode'
import { useCurrentStream } from 'packages/app/modules/streams/useCurrentStream'
import { AppContext } from 'packages/app/providers'

interface StreamSelectProps {
  className?: string
}

export const StreamSelect: FC<StreamSelectProps> = ({ className }) => {
  const appContext = useContext(AppContext)
  const { currentStream, setStream } = useCurrentStream()
  const { profile } = useProfile()

  if (!profile?.streams?.length || profile.streams.length === 1) {
    return null
  }

  return (
    <Select
      className={className}
      onChange={(value) => value && setStream(appContext.modules.streams[value])}
      options={profile?.streams?.map((item) => ({
        value: titleStreamToCode(item?.title),
        label: item ? item.title : '',
        meta: item,
      }))}
      value={currentStream?.name}
    />
  )
}
