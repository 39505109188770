import { t } from '@jume/localization'
import { Views } from 'prgm/scorecard/constants'
import { object, ObjectSchema, string } from 'yup'

interface Schema {
  controlYear?: string
  targetYear?: string
  view: string
}

export const scorecardSettings: ObjectSchema<Schema> = object().shape({
  view: string().required(t('validationErrors.required')),
  controlYear: string().when('view', {
    is: Views.YearToYear,
    then: (schema) => schema.required(t('validationErrors.required')),
  }),
  targetYear: string().when('view', {
    is: Views.YearToYear,
    then: (schema) => schema.required(t('validationErrors.required')),
  }),
})
