import { CurrentGranularitySlice } from 'jume/granularities/store/useCurrentGranularity/types'
import { StateCreator } from 'zustand'

export const createBlockBGranularitySliceSlice: StateCreator<{
  blockB: CurrentGranularitySlice
}> = (set) => ({
  blockB: {
    current: null,
    set: (current) => set(({ blockB }) => ({ blockB: { ...blockB, current } })),
    clear: () => set(({ blockB }) => ({ blockB: { ...blockB, current: null } })),
  },
})
