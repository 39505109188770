import { getLang } from '@jume/localization'
import { Granularities } from 'constants/granularities'
import {
  addDays,
  addHours,
  addYears,
  format,
  fromUnixTime,
  isToday,
  isValid,
  lastDayOfYear,
  parse,
  startOfWeek,
  subYears,
} from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { DateFormat } from 'interfaces/components.interfaces'
import { months } from 'locale/date'
import type { Moment } from 'moment/moment'

const dateFormat = 'dd.MM.uuuu'

export const toDate = (date: Date | number | string) => {
  let dateMessage
  if (date instanceof Date) {
    dateMessage = date
  } else if (typeof date === 'string') {
    dateMessage = new Date(date)
  } else {
    dateMessage = fromUnixTime(date.toString().length > 11 ? date / 1000 : date)
  }
  return dateMessage
}

export const getFormattedDate = (date?: DateFormat) => {
  const defaultValue = {
    date: undefined,
    formatDate: '',
    formatTime: '',
    formatTimeDate: '',
    formatDateMonth: '',
    formatDateTime: '',
  }

  if (!date) {
    return defaultValue
  }

  const dateMessage = toDate(date)

  if (!isValid(dateMessage)) {
    return defaultValue
  }

  const formatTime = format(dateMessage, 'HH:mm')
  const formatDate = format(dateMessage, dateFormat)
  const formatDateMonth =
    format(dateMessage, 'd ') + months[getLang()][dateMessage.getMonth()][1] + format(dateMessage, ' uuuu')
  const formatTimeDate = `${formatTime}, ${formatDate}`
  const formatDateTime = `${formatDate}, ${formatTime}`

  return { formatDate, formatTime, formatTimeDate, formatDateTime, formatDateMonth, date: dateMessage }
}

export const formatDate = (date?: DateFormat, mode: 'date' | 'month' = 'date') => {
  if (!date) {
    return ''
  }
  const dateMessage = toDate(date)
  if (!isValid(dateMessage)) {
    return ''
  }
  return format(dateMessage, mode === 'date' ? dateFormat : 'MM.uuuu')
}

export const shortDate = (date?: DateFormat) => {
  if (!date) {
    return ''
  }
  const dateMessage = toDate(date)
  if (!isValid(dateMessage)) {
    return ''
  }
  return format(dateMessage, 'dd.MM.yy')
}

export const parseDate = (date: string, template?: string) => parse(date, template ?? dateFormat, new Date())

export const formatDateTime = (date?: DateFormat) => {
  if (!date) {
    return ''
  }
  const dateMessage = toDate(date)
  if (!isValid(dateMessage)) {
    return ''
  }
  return format(dateMessage, 'dd.MM.uuuu HH:mm')
}

export const shortedToday = (date?: DateFormat) => {
  if (!date) {
    return ''
  }
  const dateMessage = toDate(date)
  if (!isValid(dateMessage)) {
    return ''
  }
  return isToday(dateMessage) ? format(dateMessage, 'HH:mm') : format(dateMessage, 'dd.MM.uuuu HH:mm')
}

export const formatDateTimeWithComma = (date?: DateFormat) => {
  if (!date) {
    return ''
  }
  const dateMessage = toDate(date)
  if (!isValid(dateMessage)) {
    return ''
  }
  return format(dateMessage, 'dd.MM.uuuu, HH:mm')
}

export const getCurrentYear = (): string => {
  const now = new Date()
  return format(now, 'yyyy')
}

export const dateToServer = (date: Date | number | string) => format(new Date(date), 'uuuu-MM-dd')

export const getFirstDayYear = (year: number | string) => new Date(Number(year), 0, 1)
export const getLastDayYear = (year: number | string) => lastDayOfYear(getFirstDayYear(year))

export const toMidnight = (date: Date | string | number) => {
  const d = new Date(date)
  d.setHours(0, 0, 0, 0)
  return d
}

export const roundToDay = (date: Date | string | number): Date => {
  const d = new Date(date)
  if (d.getHours() >= 12) {
    d.setDate(d.getDate() + 1)
  }
  d.setHours(0, 0, 0, 0)
  return d
}

export const getTodayDate = () => toMidnight(new Date())
export const getTodayTimestamp = () => toMidnight(new Date()).getTime()
export const getTomorrowDate = () => toMidnight(addDays(new Date(), 1))
export const getTomorrowTimestamp = () => getTomorrowDate().getTime()

export const addYear = (date: Date | string | number, granularityCode?: Granularities | null, years = 1) => {
  if (granularityCode === Granularities.Weeks) {
    return startOfWeek(addYears(toMidnight(new Date(date)), years), { weekStartsOn: 1 })
  }
  return addYears(toMidnight(new Date(date)), years)
}

export const subYear = (date: Date | string | number, granularityCode?: Granularities | null, years = 1) => {
  if (granularityCode === Granularities.Weeks) {
    return startOfWeek(subYears(toMidnight(new Date(date)), years), { weekStartsOn: 1 })
  }
  return subYears(toMidnight(new Date(date)), years)
}

export const datesToTimestamp = (dates: Date[]): number[] => dates.map((date) => date.getTime())

export const isValidDate = (value?: string | Date | null): value is string | Date => {
  if (!value) {
    return false
  }
  return !Number.isNaN(new Date(value).getTime())
}

export const dateByMode = (value: string, mode: 'date' | 'month') => (mode === 'month' ? '01.' : '') + value

export const isValidFormattedDate = (value: string) => isValidDate(parseDate(value))

export const isDisabledBeforeToday = (disabledBeforeToday: boolean | undefined, timestamp: number) =>
  disabledBeforeToday && timestamp < toMidnight(new Date()).getTime()

export const isDisabledBeforeDate = (
  disabledBeforeDate: Date | string | number | null | undefined,
  timestamp: number,
) => disabledBeforeDate && timestamp < toMidnight(new Date(disabledBeforeDate)).getTime()

export const isDisabledAfterDate = (disabledAfterDate: Date | string | number | null | undefined, timestamp: number) =>
  disabledAfterDate && timestamp >= addDays(toMidnight(new Date(disabledAfterDate)), 1).getTime()

export const disabledDate = (
  disabledBeforeToday?: boolean,
  disabledBeforeDate?: Date | string | number | null,
  disabledAfterDate?: Date | string | number | null,
): ((current?: Moment) => boolean) | undefined => {
  if (!disabledBeforeToday && !disabledBeforeDate && !disabledAfterDate) {
    return
  }
  return (current?: Moment) => {
    if (!current) {
      return false
    }
    if (isDisabledBeforeToday(disabledBeforeToday, current.valueOf())) {
      return true
    }
    if (isDisabledBeforeDate(disabledBeforeDate, current.valueOf())) {
      return true
    }
    if (isDisabledAfterDate(disabledAfterDate, current.valueOf())) {
      return true
    }
    return false
  }
}

export const dateForFiltration = (date = new Date()) => format(addHours(date, -2), 'yyyy-MM-dd')
export const dateForDefaultTitle = (date = new Date()) => format(addHours(date, -2), 'yyyy.MM.dd').replace(/[.]/g, '')
export const timeForTitle = (date = new Date()) => format(addHours(date, -2), 'hh.mm').replace(/[.]/g, '')

export const timeMoscowForTitle = (date = new Date()) =>
  formatInTimeZone(date, 'Europe/Moscow', 'HH.mm').replace(/[.]/g, '')
export const dateMoscowForTitle = (date = new Date()) =>
  formatInTimeZone(date, 'Europe/Moscow', 'yyyy.MM.dd').replace(/[.]/g, '')

export const dateMoscowForAuthor = (date: Date | string) => formatInTimeZone(date, 'Europe/Moscow', 'dd.MM.yyyy HH:mm')

export const formatDateTimeMoscow = (date?: DateFormat) => {
  if (!date) {
    return ''
  }
  const dateMessage = toDate(date)
  if (!isValid(dateMessage)) {
    return ''
  }
  return formatInTimeZone(date, 'Europe/Moscow', 'dd.MM.uuuu, HH:mm')
}

export const dateFromNumberString = (string?: string) =>
  string ? format(new Date(`${string.slice(0, 4)}.${string?.slice(4, 6)}.${string?.slice(6)}`), 'uuuu.MM.dd') : ''

export const getTodayDateWithMonthAfter = (monthsCount = 1) => {
  const today = new Date()
  const future = new Date(today)
  future.setMonth(today.getMonth() + monthsCount)
  return future
}

export const replaceServerDate = (str: string) => str.replace(/\d{4}-\d{2}-\d{2}/g, formatDate)

export const formatSecondsToTime = (secondsWithMilliseconds?: number | string | null) => {
  secondsWithMilliseconds = secondsWithMilliseconds === null ? null : String(secondsWithMilliseconds)
  if (!secondsWithMilliseconds || secondsWithMilliseconds.includes('-')) {
    return ''
  }

  const totalSeconds = parseFloat(secondsWithMilliseconds.replace(',', '.'))
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = Math.floor(totalSeconds % 60)

  const timeArray: (string | number)[] = []
  if (hours > 0) {
    timeArray.push(hours)
  }
  timeArray.push(minutes)
  timeArray.push(seconds)

  return timeArray.map((unit) => (typeof unit === 'number' && unit < 10 ? '0' : '') + unit).join(':')
}

export const transformWeeks = (inputString: string) => {
  const match = inputString.match(/^(W|TW)(\d+)([A-Za-z]*)\s+(\d{4})$/)

  if (!match) {
    return inputString
  }

  const [, prefix, numbers, letters, year] = match
  return `${prefix}${numbers}${letters ? letters : ''}'${year.slice(2)}`
}
