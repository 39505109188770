import { createEndpoint, NoParamEndpointConstructor, OneParamEndpointConstructor } from '@jume/core'

const baseUrl = '/projects'

interface Endpoints {
  getProject: OneParamEndpointConstructor
  getProjects: NoParamEndpointConstructor
  getProjectInfo: OneParamEndpointConstructor
  updateCurrentProject: NoParamEndpointConstructor
}

export const projectsEndpoints: Endpoints = {
  getProject: (projectId) => createEndpoint(`${baseUrl}/:projectId`, { projectId }),
  getProjects: () => createEndpoint(baseUrl),
  getProjectInfo: (projectId) => createEndpoint(`${baseUrl}/:projectId/info`, { projectId }),
  updateCurrentProject: () => createEndpoint('/account/personal-info'),
}
