import { devtools } from '@jume/utils'
import { SelectionTabsKeys } from 'demand/selections/interfaces/common'
import { create } from 'zustand'

import { UseSelectionTab } from './types'

export const useSelectionTab = create<UseSelectionTab>()(
  devtools(
    (set) => ({
      currentTab: SelectionTabsKeys.Time,
      setTab: (currentTab) => set({ currentTab }),
      clear: () => set({ currentTab: SelectionTabsKeys.Time }),
    }),
    {
      store: 'selectionTab',
    },
  ),
)
