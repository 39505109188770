import { devtools } from '@jume/utils'
import { create } from 'zustand'

interface UseStepsDateValidation {
  isValid: boolean
  message: string
  setErrorMessage: (datesValid: string) => void
  clearErrorMessage: () => void
}

export const useStepsDateValidation = create<UseStepsDateValidation>()(
  devtools(
    (set) => ({
      isValid: true,
      message: '',
      setErrorMessage: (message) => set({ message, isValid: false }),
      clearErrorMessage: () => set({ isValid: true, message: '' }),
    }),
    {
      store: 'jobsStepsDatesValidation',
    },
  ),
)
