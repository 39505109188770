import { devtools } from '@jume/utils'
import { create } from 'zustand'

import { UseMasterDataOpenUpload } from './types'

export const useMasterDataOpenUpload = create<UseMasterDataOpenUpload>()(
  devtools(
    (set) => ({
      open: false,
      setOpen: (open) => set({ open }),
    }),
    {
      store: 'masterDataOpenUpload',
    },
  ),
)
