import { devtools } from '@jume/utils'
import { UseAppliedForecastFilters } from 'jume/forecastFilters/store/useAppliedForecastFilters/types'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useAppliedForecastFilters = create<UseAppliedForecastFilters>()(
  devtools(
    persist(
      (set) => ({
        clearAppliedFilters: () => {
          set({ filters: null })
          set({ filterInputs: {} })
          set({ selectedParentIds: {} })
          set({ selectedElementsNames: {} })
        },
        filters: null,
        set: (filters) => set({ filters }),
        clear: () => set({ filters: null }),
        filterInputs: {},
        setFilterInputs: (filterInputs) => set((prev) => ({ filterInputs: { ...prev.filterInputs, ...filterInputs } })),
        clearFilterInputs: () => set({ filterInputs: {} }),
        selectedParentIds: {},
        setSelectedParentIds: (selectedParentIds) =>
          set((prev) => ({ selectedParentIds: { ...prev.selectedParentIds, ...selectedParentIds } })),
        selectedElementsNames: {},
        setSelectedElementsNames: (selectedElementsNames) =>
          set((prev) => ({ selectedElementsNames: { ...prev.selectedElementsNames, ...selectedElementsNames } })),
      }),
      {
        name: 'forecastFilters',
      },
    ),
    {
      store: 'forecastFilters',
    },
  ),
)
