import { useQueryClient } from '@jume/query'
import { useMatchRoute, useNavigate } from '@tanstack/react-router'
import { DataSourcesQueries } from 'jume/dataSources/queries/types'
import { useCurrentDelistingTab, useDelistingErrors, useUpdateDelistingTable } from 'jume/delistingTable/store'
import { useClearCashForecast } from 'jume/forecast/handlers'
import { useSavedManualCorrections, useUnsavedManualCorrections } from 'jume/forecast/store'
import { useAppliedForecastFilters } from 'jume/forecastFilters/store'
import { useCurrentGranularity } from 'jume/granularities/store'
import { useICActions, useUpdateICTable } from 'jume/innovationCalculator/store'
import { useClearInnovationDashboard } from 'jume/innovationDashboard/handlers'
import { useAppliedIDFilters } from 'jume/innovationDashboardFilters/store'
import { useCurrentInnovationTab, useInnovationErrors, useUpdateInnovationTable } from 'jume/innovationTable/store'
import { useClearCashMasterData } from 'jume/masterData/handlers'
import { useCurrentPromoTab, usePromoErrors, useUpdatePromoTable } from 'jume/promoTable/store'
import { RecommendationsQueries } from 'jume/recommendations/queries/types'
import { useClearCashStatusDashboard } from 'jume/statusDashboard/handlers'
import { useAppliedStatusFilters } from 'jume/statusFilters/store'
import { useCurrentStatusTab } from 'jume/statusTable/store'
import { useCurrentTransitionTab, useTransitionErrors, useUpdateTransitionTable } from 'jume/transitionTable/store'

export const useClearAppCashJume = () => {
  const navigate = useNavigate()
  const matchRoute = useMatchRoute()
  const queryClient = useQueryClient()

  const { clearCashStatusDashboard } = useClearCashStatusDashboard()
  const { clearInnovationDashboard } = useClearInnovationDashboard()
  const { clearCashMasterData } = useClearCashMasterData()
  const { clearCashForecast } = useClearCashForecast()

  const clearSavedManualCorrections = useSavedManualCorrections((state) => state.clear)
  const clearUnsavedManualCorrections = useUnsavedManualCorrections((state) => state.clear)
  const clearGranularityBlockA = useCurrentGranularity((state) => state.blockA.clear)
  const clearGranularityBlockB = useCurrentGranularity((state) => state.blockB.clear)
  const clearForecastFilters = useAppliedForecastFilters((state) => state.clearAppliedFilters)
  const clearInnovationTableErrors = useInnovationErrors((state) => state.clear)
  const clearTransitionTableErrors = useTransitionErrors((state) => state.clear)
  const clearDelistingTableErrors = useDelistingErrors((state) => state.clear)
  const clearPromoTableErrors = usePromoErrors((state) => state.clear)
  const clearICTable = useUpdateICTable((state) => state.clearCash)
  const clearICActions = useICActions((state) => state.clear)
  const clearTransitionTable = useUpdateTransitionTable((state) => state.clear)
  const clearPromoTable = useUpdatePromoTable((state) => state.clear)
  const clearInnovationTable = useUpdateInnovationTable((state) => state.clear)
  const clearDelistingTable = useUpdateDelistingTable((state) => state.clear)
  const clearIDFilters = useAppliedIDFilters((state) => () => state.clear)
  const clearIDFiltersInputs = useAppliedIDFilters((state) => () => state.clearFilterInputs)
  const clearStatusFilters = useAppliedStatusFilters((state) => () => state.clear)
  const clearStatusFiltersInputs = useAppliedStatusFilters((state) => () => state.clearFilterInputs)
  const clearStatusTab = useCurrentStatusTab((state) => state.clear)
  const clearDelistingTab = useCurrentDelistingTab((state) => state.clear)
  const clearInnovationTab = useCurrentInnovationTab((state) => state.clear)
  const clearPromoTab = useCurrentPromoTab((state) => state.clear)
  const clearTransitionTab = useCurrentTransitionTab((state) => state.clear)

  const isHierarchyPage = !!matchRoute({ to: '/jume/master-data/$hierarchyId' })
  const isForecastPage = !!matchRoute({ to: '/jume/project/$projectId/forecast' })

  // Удаление данных пользовательского ввода
  const clearEditData = () => {
    clearUnsavedManualCorrections()
    clearForecastFilters()
    clearICTable()
    clearICActions()
    clearTransitionTable()
    clearPromoTable()
    clearInnovationTable()
    clearDelistingTable()
    clearIDFilters()
    clearIDFiltersInputs()
    clearStatusFilters()
    clearStatusFiltersInputs()
  }

  const clearAppCashJume = (newProjectId?: number, isLogout?: boolean) => {
    queryClient.removeQueries({ queryKey: [RecommendationsQueries.RecommendationsTable] })
    queryClient.removeQueries({ queryKey: [DataSourcesQueries.DataSources] })
    queryClient.removeQueries({ queryKey: [DataSourcesQueries.DataStatus] })

    clearCashStatusDashboard()
    clearInnovationDashboard()
    clearCashMasterData()
    clearCashForecast()
    clearSavedManualCorrections()
    clearGranularityBlockA()
    clearGranularityBlockB()
    clearEditData()
    clearInnovationTableErrors()
    clearTransitionTableErrors()
    clearPromoTableErrors()
    clearDelistingTableErrors()
    clearStatusTab()
    clearDelistingTab()
    clearInnovationTab()
    clearPromoTab()
    clearTransitionTab()

    if (!isLogout) {
      if (isHierarchyPage) {
        navigate({ to: '/jume/master-data' })
      }
      if (isForecastPage) {
        navigate({ to: '/jume/project/$projectId/forecast', params: { projectId: String(newProjectId) } })
      }
    }
  }

  return {
    clearEditData,
    clearAppCashJume,
  }
}
