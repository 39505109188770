import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'packages/app'

export const Route = createFileRoute('/opti/rtm-optimizer/rtm-scenario/$scenarioId')({
  component: createPage({
    lazyImport: {
      fn: () => import('opti/rtmScenario/components/RtmScenarioBlock'),
      name: 'RtmScenarioBlock',
    },
  }),
})
