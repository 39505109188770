import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'packages/app'

export const Route = createFileRoute('/ppds/resource-families')({
  component: createPage({
    titleKey: 'titles.ppdsResourceFamilies',
    lazyImport: {
      fn: () => import('ppds/resourceFamilies/components/ResourceFamiliesBlock'),
      name: 'ResourceFamiliesBlock',
    },
  }),
})
