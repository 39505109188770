import { AppState } from 'packages/app/appState'
import { defineCurrentStream } from 'packages/app/modules'

export const defineStartPage = (appState: AppState) => {
  const currentStream = defineCurrentStream(appState)
  if (
    currentStream &&
    appState.modules?.streams &&
    Object.keys(appState.modules.streams).includes(currentStream.name)
  ) {
    return appState.modules.streams[currentStream.name].startPage
  }

  return '/'
}
