import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'utils/createPage'

export const Route = createFileRoute('/deploy/pped/$scenarioId')({
  component: createPage({
    titleKey: 'titles.stockAllocation',
    lazyImport: {
      fn: () => import('deploy/pages/pped/PPED'),
      name: 'PPED',
    },
  }),
})
