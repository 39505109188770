import { devtools } from '@jume/utils'
import { create } from 'zustand'

import { UseIsNoDataChart } from './types'

export const useIsNoDataChart = create<UseIsNoDataChart>()(
  devtools(
    () => ({
      chartA: {},
      chartB: {},
    }),
    {
      store: 'isNoDataChart',
    },
  ),
)
