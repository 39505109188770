import { createI18n } from '@jume/localization'
import { keySetsEn } from 'locale/en'
import { keySetsRu } from 'locale/ru'

export const localizationKeySets = {
  ru: keySetsRu,
  en: keySetsEn,
}

export const localization = createI18n(localizationKeySets)
