import { LoginData } from '@jume/core'
import { t } from '@jume/localization'
import { object, ObjectSchema, string } from 'yup'

export const loginSchema: ObjectSchema<LoginData> = object().shape({
  email: string()
    .email(() => t('validationErrors.incorrectEmailFormat'))
    .required(() => t('validationErrors.required')),
  password: string()
    .trim()
    .required(() => t('validationErrors.required')),
})
