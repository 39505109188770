import { useJobsParams } from 'modules/jobs/store'

export const useClearAppCashJobs = () => {
  const { clearSearch } = useJobsParams()

  const clearAppCashJobs = () => {
    clearSearch()
  }

  return { clearAppCashJobs }
}
