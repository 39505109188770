import { useQueryClient } from '@jume/query'
import { ForecastQueries } from 'jume/forecast/queries/types'
import { BlockAQueries } from 'jume/forecastBlockA/queries/types'
import { BlockBQueries } from 'jume/forecastBlockB/queries/types'
import { KpiQueries } from 'jume/kpi/queries/types'
import { useProject } from 'packages/app'

export const useClearCashForecast = () => {
  const queryClient = useQueryClient()
  const { project } = useProject()

  const clearCashForecast = () => {
    queryClient.removeQueries({ queryKey: [ForecastQueries.Forecast, project?.id] })
    queryClient.removeQueries({ queryKey: [BlockAQueries.ForecastChart] })
    queryClient.removeQueries({ queryKey: [BlockBQueries.DemandDriverChart] })
    queryClient.removeQueries({ queryKey: [KpiQueries.ZoneOne] })
    queryClient.removeQueries({ queryKey: [KpiQueries.ZoneTwo] })
    queryClient.removeQueries({ queryKey: [KpiQueries.ZoneThree] })
    queryClient.removeQueries({ queryKey: [KpiQueries.ZoneFour] })
  }

  return { clearCashForecast }
}
