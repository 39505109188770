import { RecommendationsSwitch } from 'demand/recommendations/components'
import { ModuleConnector } from 'packages/app'

export const demandRecommendationsModule: ModuleConnector = (options) => ({
  ...options,
  menuItems: {
    recommendations: {
      component: <RecommendationsSwitch />,
    },
  },
})
