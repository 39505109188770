export const colors = {
  line1: '#0ed8da',
  line2: '#73c4eb',
  line3: '#94d8ed',
  line4: '#92ceb8',
  line5: '#93db9e',
  line6: '#b0d268',
  line7: '#c4dd60',
  line8: '#e4a5f3',
  line9: '#bea3f8',
  line10: '#a4b8f6',
  line11: '#d982ee',
  black: '#595959',
  labels: '#bfbfbf',
  grid: '#f0f0f0',
  outlineColor: '#bdbdbd',
  handleBorderColor: '#818181',
  redLight: '#f69e9e',
  greenBg: '#93db9e',
  blueBg: '#a4b8f6',
  yellow: '#f8ea74',
}
