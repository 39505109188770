import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'packages/app'

export const Route = createFileRoute('/prgm/reports')({
  component: createPage({
    titleKey: 'titles.prgmReports',
    lazyImport: {
      fn: () => import('prgm/reports/components/ReportsBlock'),
      name: 'ReportsBlock',
    },
  }),
})
