import { createContext, ReactNode } from 'react'

import { defaultGlobalContextValue, GlobalContextProps, GlobalOptions } from '@jume/core'
import { AppModulesState, defaultModulesState } from 'packages/app/modules'

const defaultAppContextValue: GlobalContextProps<AppModulesState> = {
  ...defaultGlobalContextValue,
  modules: defaultModulesState,
}

export type AppContextProps = GlobalContextProps<AppModulesState>

export const AppContext = createContext<AppContextProps>(defaultAppContextValue)

export interface AppProviderProps<Item = unknown> extends Partial<GlobalOptions<Item>> {
  children?: ReactNode
  modules?: AppModulesState
}

export const AppProvider = <Item = unknown,>({ children, ...props }: AppProviderProps<Item>): ReactNode => (
  <AppContext.Provider value={{ ...defaultAppContextValue, ...props } as any}>{children}</AppContext.Provider>
)
