import { FC, useState } from 'react'

import { MenuGroup } from '@jume/core'
import { ArrowBottomIcon } from '@jume/icons'
import { SlideToggle, SlideToggleTypes } from '@jume/ui'

import classes from './NavMenu.module.scss'
import { NavTitle } from './NavTitle'
import { renderMenu } from './renderMenu'

interface Props {
  item: MenuGroup
}

export const NavSubMenu: FC<Props> = ({ item }) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(!!item.initialOpen)
  const toggleMenuOpen = () => setIsSubMenuOpen((prev) => !prev)

  return (
    <>
      <NavTitle
        className={classes.menuItem}
        icon={<ArrowBottomIcon />}
        isActive={isSubMenuOpen}
        onClick={toggleMenuOpen}
      >
        {item.label()}
      </NavTitle>
      <SlideToggle
        classNameHide={classes.subMenuHide}
        classNameWrap={classes.subMenu}
        open={isSubMenuOpen}
        type={SlideToggleTypes.NoTransform}
      >
        {item.items.map(renderMenu)}
      </SlideToggle>
    </>
  )
}
