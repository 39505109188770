import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'packages/app'

export const Route = createFileRoute('/opti/replen-optimizer/replen-overview')({
  component: createPage({
    titleKey: 'titles.replenOverview',
    lazyImport: {
      fn: () => import('jume/replenScenarios/components/ReplenOverview'),
      name: 'ReplenOverview',
    },
  }),
})
