import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'packages/app'

export const Route = createFileRoute('/prgm/search')({
  component: createPage({
    titleKey: 'titles.prgmSearch',
    lazyImport: {
      fn: () => import('prgm/searchPromo/components/SearchBlock'),
      name: 'SearchBlock',
    },
  }),
})
