import { t } from '@jume/localization'
import { Streams } from 'constants/streams'
import { ModuleConnector } from 'packages/app'

export const prgmCreatePromoModule: ModuleConnector = (options) => ({
  ...options,
  menuItems: {
    create: {
      label: () => t('prgm.navMenu.create'),
      href: `/${Streams.Prgm}/create`,
      dependence: (appContext) =>
        appContext.modules.permissions?.readCreateDb || appContext.modules.permissions?.editCreateDb,
    },
  },
})
