import { ScorecardTableRow, ServerScorecardRow } from 'prgm/scorecard/api'

import { getDateFromKey } from './getDateFromKey'

export const transformScenarioToServer = (rows: ScorecardTableRow[]): ServerScorecardRow[] =>
  rows.map((row) => {
    const values = Object.keys(row)
      .filter((key) => !['channel', 'channelId'].includes(key))
      .map((key) => {
        const { month, year } = getDateFromKey(key)

        return {
          month,
          year,
          value: row[key]?.value,
          date: row[key]?.date || null,
        }
      })
      .filter((value) => !!value.value)

    return {
      channelId: row.channelId,
      values,
    }
  })
