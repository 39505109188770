import { FC } from 'react'
import { Controller } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { t } from '@jume/localization'
import { Button, ButtonColors, ErrorStack, FormItem, Modal, OverlayingModal, TextField } from '@jume/ui'
import cx from 'clsx'
import { useInitForm } from 'config/rhf'
import { FeedbackForm } from 'modules/feedback/api/interfaces'
import { Rating } from 'modules/feedback/components/Rating'
import { feedbackSchema } from 'modules/feedback/helpers/validation'
import { useSendFeedback } from 'modules/feedback/queries'

import classes from './FeedbackModal.module.scss'

interface FeedbackModalProps {
  className?: string
  isOpened?: boolean
  onClose?: () => void
}

export const FeedbackModal: FC<FeedbackModalProps> = ({ className, isOpened, onClose }) => {
  const { sendFeedback, isPending, error, resetError, isSuccess } = useSendFeedback()

  const { control, handleSubmit, watch, reset } = useInitForm<FeedbackForm>({
    resolver: yupResolver(feedbackSchema),
    defaultValues: {
      comment: '',
    },
  })

  const rating = watch('rating')

  const onSubmit = (data: FeedbackForm) => {
    sendFeedback(data)
  }

  const onInnerClose = () => {
    onClose?.()
    reset()
    resetError()
  }

  return (
    <OverlayingModal disabledScroll isOpened={isOpened} onClose={onInnerClose}>
      <Modal
        className={cx(classes.popup, className)}
        classNameTitle={classes.title}
        onClose={onInnerClose}
        title={isSuccess ? t('feedback.success') : t('feedback.title')}
      >
        {!isSuccess && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              name="rating"
              render={({ field, fieldState: { error: errorInput } }) => (
                <FormItem error={errorInput?.message}>
                  <Rating onChange={field.onChange} value={field.value} />
                </FormItem>
              )}
            />
            {rating > 0 && (
              <>
                <p>{t('feedback.description')}</p>
                <Controller
                  control={control}
                  name="comment"
                  render={({ field, fieldState: { error: errorInput } }) => (
                    <FormItem error={errorInput?.message}>
                      <TextField
                        className={classes.input}
                        multiline
                        onChange={field.onChange}
                        placeholder={t('required')}
                        value={field.value}
                      />
                    </FormItem>
                  )}
                />
                <Button color={ButtonColors.Primary} fullWidth htmlType="submit" loading={isPending}>
                  {t('feedback.send')}
                </Button>
                <ErrorStack classNameMessage={classes.errorMessage} enableAnimation errors={error} />
              </>
            )}
          </form>
        )}
      </Modal>
    </OverlayingModal>
  )
}
