import { AppState } from 'packages/app/appState'

import { isValidStreamForCurrentUser } from './isValidStreamForCurrentUser'
import { Stream } from './types'

export const defineCurrentStream = (appState: AppState): Stream | null => {
  const streamByUrl = location.pathname.split('/')[1]
  const lastPage = window.sessionStorage.getItem('lastPage') ?? window.localStorage.getItem('lastPage')
  const streamByLastPage = lastPage?.split('/')[1]

  if (isValidStreamForCurrentUser(appState, streamByUrl)) {
    return appState.modules?.streams[streamByUrl] as Stream
  } else if (streamByLastPage && isValidStreamForCurrentUser(appState, streamByLastPage)) {
    return appState.modules?.streams[streamByLastPage] as Stream
  }
  const firstStream = appState.modules?.profile?.streams?.[0]?.title.toLowerCase()
  if (firstStream) {
    return appState.modules?.streams[firstStream] as Stream
  }
  return null
}
