import { devtools } from '@jume/utils'
import { RealignmentTypes, RealignmentUpdateItem } from 'demand/realignment/api'
import { generateErrorsState, UseErrors, ValidationError } from 'modules/errorsState'
import { create } from 'zustand'

export type RealignmentError = ValidationError<RealignmentTypes, keyof Omit<RealignmentUpdateItem, 'id'> | 'row'>
export type UseRealignmentErrors = UseErrors<RealignmentError>

export const useRealignmentErrors = create<UseRealignmentErrors>()(
  devtools((set, get) => generateErrorsState<RealignmentError>(set, get), {
    store: 'demand.realignment.errors',
  }),
)
