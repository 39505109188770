import { t } from '@jume/localization'
import { Streams } from 'constants/streams'
import { ModuleConnector } from 'packages/app'

export const demandForecastLoadModule: ModuleConnector = (options) => ({
  ...options,
  menuItems: {
    forecastLoad: {
      label: () => t('menu.forecastLoad'),
      href: `/${Streams.Demand}/forecast-data-upload`,
      dependence: (appContext) => appContext.modules.permissions?.viewForecastUploadDashboard,
    },
  },
})
