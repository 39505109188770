import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'packages/app'

export const Route = createFileRoute('/milk-planning/data-hub/')({
  component: createPage({
    titleKey: 'titles.milk.dataHub',
    lazyImport: {
      fn: () => import('milkPlanning/dataHub/components/DataHubPage'),
      name: 'DataHubPage',
    },
  }),
})
