import { useEffect } from 'react'

import { useDebounceFn } from 'ahooks'
import { useLogout } from 'packages/app/modules/logout'
import { useLoadingFileCount } from 'packages/app/modules/session/store'
import { useCurrentStream } from 'packages/app/modules/streams'

export const useSessionOver = (cb?: () => void) => {
  const { currentStream } = useCurrentStream()
  const isLoadingFile = useLoadingFileCount((state) => state.fileCount > 0)
  const { logout } = useLogout()

  const { run: sessionOverDebounce, cancel: cancelSessionOver } = useDebounceFn(
    () => {
      if (!currentStream?.session?.enabled || !currentStream?.session?.timeOfInactivity) {
        return
      }
      cb?.()
      window.addEventListener('beforeunload', logout)
    },
    { wait: currentStream?.session?.timeOfInactivity || 0 },
  )

  const addEvents = () => {
    window.addEventListener('mousemove', sessionOverDebounce)
    window.addEventListener('mouseup', sessionOverDebounce)
    window.addEventListener('mousedown', sessionOverDebounce)
    window.addEventListener('wheel', sessionOverDebounce)
    window.addEventListener('keyup', sessionOverDebounce)
    window.addEventListener('keydown', sessionOverDebounce)
  }

  const removeEvents = () => {
    window.removeEventListener('mousemove', sessionOverDebounce)
    window.removeEventListener('mouseup', sessionOverDebounce)
    window.removeEventListener('mousedown', sessionOverDebounce)
    window.removeEventListener('wheel', sessionOverDebounce)
    window.removeEventListener('keyup', sessionOverDebounce)
    window.removeEventListener('keydown', sessionOverDebounce)
  }

  useEffect(() => {
    if (isLoadingFile) {
      removeEvents()
      cancelSessionOver()
      return
    }

    sessionOverDebounce()
    addEvents()

    return () => {
      removeEvents()
      cancelSessionOver()
    }
  }, [isLoadingFile])

  useEffect(() => () => window.removeEventListener('beforeunload', logout), [])
}
