import { createEndpoint, NoParamEndpointConstructor } from '@jume/core'

const baseUrl = '/account'

interface Endpoints {
  getInfo: NoParamEndpointConstructor
}

export const profileEndpoints: Endpoints = {
  getInfo: () => createEndpoint(`${baseUrl}/personal-info`),
}
