import { deleteEmptyWithArrays, devtools } from '@jume/utils'
import { create } from 'zustand'

import { UseSelectedMasterDataTable } from './types'

export const useSelectedMasterDataTable = create<UseSelectedMasterDataTable>()(
  devtools(
    (set) => ({
      selectedIds: {},
      setSelectedIds: (hierarchyId) => (state) => {
        set((prev) => {
          if (!hierarchyId) {
            return prev
          }
          const selectedIds = typeof state === 'function' ? state(prev.selectedIds[hierarchyId] || []) : state
          return {
            selectedIds: deleteEmptyWithArrays({
              ...prev.selectedIds,
              [hierarchyId]: selectedIds,
            }),
          }
        })
      },
      clear: () => set({ selectedIds: {} }),
    }),
    {
      store: 'selectedMasterDataTable',
    },
  ),
)
