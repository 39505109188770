import { devtools } from '@jume/utils'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface UseEditComment {
  comment: string
  open: boolean
  setOpen: (open: boolean) => void
  setComment: (comment: string) => void
  clear: () => void
}

export const useEditComment = create<UseEditComment>()(
  devtools(
    persist(
      (set) => ({
        comment: '',
        open: true,
        setOpen: (open) => set({ open }),
        setComment: (comment) => set({ comment }),
        clear: () => set({ comment: '', open: true }),
      }),
      {
        name: 'prgmEditComments',
      },
    ),
  ),
)
