import { lazy } from 'react'

import { IS_DEV_MODE } from '@jume/core'

export const TanStackRouterDevtools = IS_DEV_MODE
  ? lazy(() =>
      import('@tanstack/router-devtools').then((res) => ({
        default: res.TanStackRouterDevtools,
      })),
    )
  : () => null
