import { createEndpoint, NoParamEndpointConstructor } from '@jume/core'

const baseUrl = '/gitlab-deploy-status'

interface Endpoints {
  getUpdatingStatus: NoParamEndpointConstructor
}

export const systemUpdatingEndpoints: Endpoints = {
  getUpdatingStatus: () => createEndpoint(baseUrl),
}
