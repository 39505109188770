import { FC } from 'react'

import { CellContext } from '@tanstack/table-core'
import { ScorecardColumn } from 'prgm/scorecard/api'
import { useTargetScenario } from 'prgm/scorecard/stores'

import { TargetScenarioSelect } from './TargetScenarioSelect'

interface Props {
  cellContext: CellContext<any>
  column: ScorecardColumn
}

export const TargetScenarioCell: FC<Props> = ({ cellContext, column }) => {
  const setValue = useTargetScenario((state) => state.setValue)
  const columnDate = `${('00' + column.month).slice(-2)}${column.year}`

  const value = useTargetScenario((state) => state.targetScenarioValues[cellContext.row.index]?.[columnDate])

  return (
    <TargetScenarioSelect
      column={column}
      rowId={cellContext.row.index}
      rowKey={columnDate}
      setValue={setValue}
      value={value}
    />
  )
}
