import { devtools } from '@jume/utils'
import { PromoTabsCodes } from 'jume/promoTable/interfaces/common'
import { create } from 'zustand'

import { UseCurrentPromoTab } from './types'

export const useCurrentPromoTab = create<UseCurrentPromoTab>()(
  devtools(
    (set) => ({
      currentTab: PromoTabsCodes.All,
      setCurrentTab: (currentTab) => set({ currentTab }),
      clear: () => set({ currentTab: PromoTabsCodes.All }),
    }),
    {
      store: 'currentPromoTab',
    },
  ),
)
