import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'packages/app'

export const Route = createFileRoute('/demand/forecast-data-upload')({
  component: createPage({
    titleKey: 'titles.forecastLoading',
    lazyImport: {
      fn: () => import('demand/forecastLoad/components/ForecastUploadCards'),
      name: 'ForecastUploadCards',
    },
  }),
})
