import { useCallback } from 'react'

import { broadcastLogout, getTokens, logoutApi, useLogoutClear } from '@jume/core'
import { global } from '@jume/utils'
import { AppContextProps } from 'packages/app/providers'

export const useLogoutByAppContext = (appContext: AppContextProps) => {
  const { logoutClear } = useLogoutClear(appContext.auth.logout, appContext)

  const logout = useCallback(() => {
    const { refreshToken } = getTokens()
    if (refreshToken) {
      logoutApi.logout({ refresh: refreshToken })
    }
    global.logout = () => {
      appContext.auth.setAuthenticated(false)
      logoutClear()
      broadcastLogout()
    }
    global.logout()
  }, [logoutClear, broadcastLogout])

  return { logout }
}
