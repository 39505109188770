import { create } from 'zustand'

interface UseLoadingFileCount {
  fileCount: number
  incFileCount: () => void
  decFileCount: () => void
}

export const useLoadingFileCount = create<UseLoadingFileCount>()((set, get) => ({
  fileCount: 0,
  incFileCount: () => set((state) => ({ fileCount: state.fileCount + 1 })),
  decFileCount: () => {
    if (get().fileCount > 0) {
      set((state) => ({ fileCount: state.fileCount - 1 }))
    }
  },
}))
