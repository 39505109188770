import { devtools } from '@jume/utils'
import { create } from 'zustand'

import { UsePromoErrors } from './types'

export const usePromoErrors = create<UsePromoErrors>()(
  devtools(
    (set) => ({
      errors: [],
      clear: () => set({ errors: [] }),
      setErrors: (errors) => set({ errors }),
      removeError: (id, key) =>
        set(({ errors }) => ({ errors: errors.filter((item) => item.id !== id || item.key !== key) })),
      removeById: (id) => set(({ errors }) => ({ errors: errors.filter((item) => item.id !== id) })),
    }),
    {
      store: 'promoErrors',
    },
  ),
)
