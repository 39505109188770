import { getLang } from '@jume/localization'
import { months as monthsLang } from 'locale/date'

export const months = Array.from(Array(12)).map((_, i) => ({
  id: i + 1,
  name: monthsLang[getLang()][i][0],
}))

export const quarters = [
  { id: 1, name: 'Q1' },
  { id: 2, name: 'Q2' },
  { id: 3, name: 'Q3' },
  { id: 4, name: 'Q4' },
]
