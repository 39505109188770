import { storageTableByReceiving } from 'deploy/pages/stockAllocation/constants/storage'
import { useClearAppCash } from 'hooks/useClearAppCash'

export const useAfterLogout = () => {
  const { clearAppCash } = useClearAppCash()
  return () => {
    window.localStorage.removeItem(storageTableByReceiving)
    clearAppCash()
  }
}
