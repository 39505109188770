import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'packages/app'

export const Route = createFileRoute('/deploy/create-scenario')({
  component: createPage({
    titleKey: 'titles.deployCreateScenario',
    lazyImport: {
      fn: () => import('deploy/pages/scenario/components/DeployScenarioSettings'),
      name: 'DeployScenarioSettings',
      props: { isCreateScenario: true },
    },
  }),
})
