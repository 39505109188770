import { t } from '@jume/localization'
import { Streams } from 'constants/streams'
import { ModuleConnector } from 'packages/app'

export const milkDataHubModule: ModuleConnector = (options) => ({
  ...options,
  menuItems: {
    dataHub: {
      label: () => t('milk.dataHub.menu.dataHub'),
      href: '',
    },
    dataManagement: {
      label: () => t('milk.dataHub.menu.dataManagement'),
      href: `/${Streams.MilkPlanning}/data-hub`,
    },
  },
})
