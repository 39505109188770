import {
  getStorageCfrScenarioPFRName,
  getStoragePromoScenarioPFRName,
  getStorageRtmScenarioPFRName,
  getStorageTetrisScenarioPFRName,
} from 'constants/storage'

const storage = {
  setItem: <T>(name: string, data: T, remember: boolean = true): void => {
    const stringifiedData = JSON.stringify(data)
    if (remember) {
      window.localStorage.setItem(name, stringifiedData)
    } else {
      window.sessionStorage.setItem(name, stringifiedData)
    }
  },
  getItem: <T>(name: string): T | null => {
    const data = window.localStorage.getItem(name) || window.sessionStorage.getItem(name)
    if (!data) {
      return null
    }
    return JSON.parse(data) as T
  },
  removeItem: (key: string) => {
    window.localStorage.removeItem(key)
    window.sessionStorage.removeItem(key)
  },
  setPromoScenarioPFR: (scenarioId: number, params: any) => {
    window.localStorage.setItem(getStoragePromoScenarioPFRName(scenarioId), JSON.stringify(params))
  },
  getPromoScenarioPFR: (scenarioId: number) => {
    const paramsLS = window.localStorage.getItem(getStoragePromoScenarioPFRName(scenarioId))
    if (!paramsLS) {
      return null
    }
    return JSON.parse(paramsLS)
  },
  clearPromoScenarioPFR: (scenarioId: number) => {
    window.localStorage.removeItem(getStoragePromoScenarioPFRName(scenarioId))
  },
  setRtmScenarioPFR: (scenarioId: number, params: any) => {
    window.localStorage.setItem(getStorageRtmScenarioPFRName(scenarioId), JSON.stringify(params))
  },
  getRtmScenarioPFR: (scenarioId: number) => {
    const paramsLS = window.localStorage.getItem(getStorageRtmScenarioPFRName(scenarioId))
    if (!paramsLS) {
      return null
    }
    return JSON.parse(paramsLS)
  },
  clearRtmScenarioPFR: (scenarioId: number) => {
    window.localStorage.removeItem(getStorageRtmScenarioPFRName(scenarioId))
  },
  setTetrisScenarioPFR: (scenarioId: number, params: any) => {
    window.localStorage.setItem(getStorageTetrisScenarioPFRName(scenarioId), JSON.stringify(params))
  },
  getTetrisScenarioPFR: (scenarioId: number) => {
    const paramsLS = window.localStorage.getItem(getStorageTetrisScenarioPFRName(scenarioId))
    if (!paramsLS) {
      return null
    }
    return JSON.parse(paramsLS)
  },
  clearTetrisScenarioPFR: (scenarioId: number) => {
    window.localStorage.removeItem(getStorageTetrisScenarioPFRName(scenarioId))
  },
  setCfrScenarioPFR: (scenarioId: number, params: any) => {
    window.localStorage.setItem(getStorageCfrScenarioPFRName(scenarioId), JSON.stringify(params))
  },
  getCfrScenarioPFR: (scenarioId: number) => {
    const paramsLS = window.localStorage.getItem(getStorageCfrScenarioPFRName(scenarioId))
    if (!paramsLS) {
      return null
    }
    return JSON.parse(paramsLS)
  },
  clearCfrScenarioPFR: (scenarioId: number) => {
    window.localStorage.removeItem(getStorageCfrScenarioPFRName(scenarioId))
  },
}

export default storage
