import { useClearAppCashDemand } from 'demand/shared/handlers'
import { useClearAppCashJume } from 'jume/shared/handlers'
import { useClearAppCashJobs } from 'modules/jobs/handlers'
import { useClearAppCashOpti } from 'opti/shared/handlers'
import { useClearAppCashPrgm } from 'prgm/shared/handlers'
import { cacheForCallback } from 'utils/memoize'

export const useClearAppCash = () => {
  const { clearAppCashJume, clearEditData } = useClearAppCashJume()
  const { clearAppCashOpti } = useClearAppCashOpti()
  const { clearAppCashJobs } = useClearAppCashJobs()
  const { clearAppCashPrgm } = useClearAppCashPrgm()
  const { clearAppCashDemand } = useClearAppCashDemand()

  const clearAppCash = (isLogout?: boolean, newProjectId?: number) => {
    cacheForCallback.clear()
    clearAppCashJobs()
    clearAppCashJume(newProjectId, isLogout)
    clearAppCashOpti(newProjectId)
    clearAppCashPrgm()
    clearAppCashDemand()
  }

  return { clearAppCash, clearEditData }
}
