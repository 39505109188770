import { devtools } from '@jume/utils'
import { create } from 'zustand'

import { StatusTabsCodes, UseCurrentStatusTab } from './types'

export const useCurrentStatusTab = create<UseCurrentStatusTab>()(
  devtools(
    (set) => ({
      currentTab: StatusTabsCodes.All,
      set: (currentTab) => set({ currentTab }),
      clear: () => set({ currentTab: StatusTabsCodes.All }),
    }),
    {
      store: 'currentStatusTab',
    },
  ),
)
