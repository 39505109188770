import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'packages/app'

export const Route = createFileRoute('/deploy/transport-optimization/$scenarioId')({
  component: createPage({
    titleKey: 'titles.transportOptimization',
    lazyImport: {
      fn: () => import('deploy/pages/transportOptimization/TransportOptimization'),
      name: 'TransportOptimization',
    },
  }),
})
