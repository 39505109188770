import { devtools } from '@jume/utils'
import { xor } from 'lodash-es'
import { SkuTableRow } from 'prgm/skuTable/interfaces'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface UseSkuTable {
  rows: SkuTableRow[]
  initialSelectedIds: number[]
  open: boolean
  calculateParamsChanged: boolean
  setOpen: (open: boolean) => void
  setSkuTableRows: (rows: SkuTableRow[]) => void
  addRows: (ids: number[]) => void
  removeRow: (id: number) => void
  clear: () => void
}

export const useSkuTable = create<UseSkuTable>()(
  devtools(
    persist(
      (set) => ({
        rows: [],
        open: true,
        initialSelectedIds: [],
        calculateParamsChanged: false,
        setOpen: (open) => set({ open }),
        setSkuTableRows: (rows) => {
          set(() => {
            const rowsWithIds = rows.map((row, index) => ({
              ...row,
              id: index,
            }))

            const selectedIds = rowsWithIds.filter((row) => row.isSelected).map((row) => row.id)

            return {
              rows: rowsWithIds,
              initialSelectedIds: selectedIds,
              calculateParamsChanged: false,
            }
          })
        },
        addRows: (ids) =>
          set(({ rows, initialSelectedIds }) => {
            const updatedRows = rows.map((row) => ({
              ...row,
              isSelected: row.isSelected || ids.includes(row.id),
            }))
            const selectedIds = updatedRows.filter((row) => row.isSelected).map((row) => row.id)
            const calculateParamsChanged = !!xor(selectedIds, initialSelectedIds).length

            return { rows: updatedRows, calculateParamsChanged }
          }),
        removeRow: (id) =>
          set(({ rows, initialSelectedIds }) => {
            const updatedRows = rows.map((row) => ({
              ...row,
              isSelected: row.isSelected && id !== row.id,
            }))
            const selectedIds = updatedRows.filter((row) => row.isSelected).map((row) => row.id)
            const calculateParamsChanged = !!xor(selectedIds, initialSelectedIds).length

            return { rows: updatedRows, calculateParamsChanged }
          }),
        clear: () => set({ rows: [], initialSelectedIds: [], calculateParamsChanged: false }),
      }),
      {
        name: 'prgmSkuTable',
      },
    ),
  ),
)
