import { FC, useEffect, useState } from 'react'

import { t } from '@jume/localization'
import { Button, ButtonSizes, Modal, OverlayingModal } from '@jume/ui'
import cx from 'clsx'
import { updateSystemMinutes } from 'constants/app'
import { useUpdatingSystem } from 'packages/app/modules/systemUpdating/handlers'

import classes from './UpdatingSystem.module.scss'

export const UpdatingSystem: FC = () => {
  const [isOpened, setIsOpened] = useState<boolean | null>(false)

  const { isFutureUpdating, isUpdating } = useUpdatingSystem()

  const onClose = () => {
    if (isFutureUpdating) {
      setIsOpened(null)
    }
  }

  useEffect(() => {
    if (isOpened !== null) {
      setIsOpened(isFutureUpdating)
    }
  }, [isFutureUpdating])

  useEffect(() => {
    setIsOpened(isUpdating)
  }, [isUpdating])

  return (
    <OverlayingModal disabledScroll isOpened={!!isOpened} onClose={onClose}>
      <Modal
        className={cx(classes.popup, { [classes.small]: !isFutureUpdating })}
        classNameClose={cx(classes.close, { [classes.closable]: isFutureUpdating })}
        classNameTitle={classes.title}
        onClose={onClose}
        title={t(isFutureUpdating ? 'systemWillBeUpdate' : 'systemUpdating', { count: updateSystemMinutes })}
      >
        {isFutureUpdating && (
          <Button className={classes.okButton} onClick={onClose} size={ButtonSizes.Medium}>
            {t('ok')}
          </Button>
        )}
      </Modal>
    </OverlayingModal>
  )
}
