import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'packages/app'

export const Route = createFileRoute('/demand/master-data-upload')({
  component: createPage({
    titleKey: 'titles.sourceLoading',
    lazyImport: {
      fn: () => import('demand/masterData/components/MasterDataUploadCards'),
      name: 'MasterDataUploadCards',
    },
  }),
})
