type DeepPartial<T extends object> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P]
}

export const mergeObjects = <T extends Record<string, any>>(...objects: Array<DeepPartial<T>>): T => {
  const result = {} as T
  objects.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== null && typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        result[key as keyof T] = result[key] || {}
        result[key as keyof T] = mergeObjects(result[key], obj[key] as DeepPartial<T[keyof T]>)
      } else {
        result[key as keyof T] = obj[key] as T[keyof T]
      }
    })
  })
  return result
}

export const mergeObjectsByFirst = <T extends object, U extends object>(obj1: T, obj2: U): T & U => {
  const result: T & U = { ...obj1, ...obj2 }

  for (const key in result) {
    const val1 = obj1[key as keyof T]
    const val2 = obj2[key as keyof U]
    if (val2 !== undefined && val1 !== val2) {
      ;(result as any)[key] = val2
    }
  }

  return result
}
