import { createContext, FC, ReactNode } from 'react'

import { AuthViewer } from './authViewer'

interface AuthViewerContextProps {
  authViewer: AuthViewer | null
}

export const AuthViewerContext = createContext<AuthViewerContextProps>({
  authViewer: null,
})

interface AuthViewerProviderProps extends AuthViewerContextProps {
  children?: ReactNode
}

export const AuthViewerProvider: FC<AuthViewerProviderProps> = ({ children, ...props }) => (
  <AuthViewerContext.Provider value={props}>{children}</AuthViewerContext.Provider>
)
