import { DataSourceUploadExtendedTypes, DataSourceUploadTypes } from 'interfaces/components.interfaces'
import { RtmCreateDataSourceOptions } from 'opti/rtmDataSources/api/interfaces'

export enum TetrisScenarioTabsCodes {
  Input = 'Input',
  Output = 'Output',
}

export enum TetrisUploadStatuses {
  Failed = 'failed',
  Success = 'success',
  Loading = 'loading',
  Idle = 'idle',
  CheckingMD = 'Checking master-data',
  SeeLogFileForDetails = 'Master data warning! Look into log file',
  SeeLogFileForDetailsRu = 'Предупреждение по мастер дате! смотрите в лог-файле',
}

export type TetrisCreateDataSource = ({ data, params }: RtmCreateDataSourceOptions) => void

export interface TetrisDataSource {
  type: DataSourceUploadTypes | DataSourceUploadExtendedTypes | null
  isUploaded: boolean
  title: string
  isLoading: boolean
  date?: string | null
  error?: boolean
}

export enum TetrisDSTypes {
  Parameters = 'parameters',
  Products = 'products',
  Innovations = 'innovations',
  Uoms = 'uoms',
  Calendars = 'calendars',
  Plants = 'plants',
  Contracts = 'contracts',
  Warehouses = 'warehouses',
  Materials = 'materials',
  MaterialContents = 'materialContents',
  MaterialGroups = 'materialGroups',
  TableParameters = 'tableParameters',
  SupplyScheme = 'supplyScheme',
  FarmSalesScheme = 'farmSalesScheme',
  PlantSalesScheme = 'plantSalesScheme',
  MovementScheme = 'movementScheme',
  BuyersContracts = 'buyersContracts',
  RecoCapabilities = 'recoCapabilities',
  Derivation = 'derivation',
  MbAdjustments = 'mbAdjustments',
  Separation = 'separation',
  InboundCapacity = 'inboundCapacity',
  OutboundCapacity = 'outboundCapacity',
  StockBounds = 'stockBounds',
  BaseFormulas = 'baseFormulas',
  RecoFormulas = 'recoFormulas',
  MinBatches = 'minBatches',
  IndustrialCosts = 'industrialCosts',
  LineCapacities = 'lineCapacities',
  LinePriorities = 'linePriorities',
  MrAdjustments = 'mrAdjustments',
  LineBindings = 'lineBindings',
  Demand = 'demand',
  SourcingScheme = 'sourcingScheme',
  Deliveries = 'deliveries',
  Itineraries = 'itineraries',
  Shipments = 'shipments',
  PremadeVolumes = 'premadeVolumes',
  Rejections = 'rejections',
  DemandOptions = 'demandOptions',
}
