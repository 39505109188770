import { t } from '@jume/localization'
import { ModuleConnector } from 'packages/app'

export const deployPpedModule: ModuleConnector = (options) => ({
  ...options,
  menuItems: {
    pped: {
      label: () => t('deploy.navMenu.reporting.pped'),
      href: '/deploy/pped',
    },
  },
})
