import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'packages/app'

export const Route = createFileRoute('/opti/rtm-optimizer/rtm-scenario/not-found')({
  component: createPage({
    lazyImport: {
      fn: () => import('opti/scenarioShared/components/ScenarioNotFound'),
      name: 'ScenarioNotFound',
    },
  }),
})
