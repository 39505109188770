import { FC, PropsWithChildren, useContext } from 'react'

import { Loader, LoaderTypes } from '@jume/ui'
import { isPageInitiating } from 'packages/app/layout'
import { AppContext } from 'packages/app/providers'

export const LoaderController: FC<PropsWithChildren> = ({ children }) => {
  const appContext = useContext(AppContext)

  return isPageInitiating(appContext) ? (
    <Loader className="bg-white h100vh" type={LoaderTypes.SpinnerCenter} />
  ) : (
    children
  )
}
