import { devtools } from '@jume/utils'
import { VisibilityForecastElements } from 'jume/forecast/interfaces/common'
import { mergeObjects } from 'utils/mergeObjects'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useVisibilityForecastElements = create<VisibilityForecastElements>()(
  devtools(
    persist(
      (set) =>
        ({
          blockA: {
            open: true,
            toggle: () =>
              set(({ blockA, blockB }) => ({
                blockA: {
                  ...blockA,
                  open: !blockA.open,
                },
                blockB: {
                  ...blockB,
                  open: blockA.open ? true : blockB.open,
                },
              })),
            chart: { open: true },
            zoom: {
              open: false,
              toggle: () =>
                set(({ blockA }) => ({
                  blockA: {
                    ...blockA,
                    zoom: {
                      ...blockA.zoom,
                      open: !blockA.zoom.open,
                    },
                  },
                })),
            },
            table: {
              open: true,
              toggle: () =>
                set(({ blockA }) => ({
                  blockA: {
                    ...blockA,
                    table: {
                      ...blockA.table,
                      open: !blockA.table.open,
                    },
                  },
                })),
            },
            value: {
              open: true,
              toggle: () =>
                set(({ blockA }) => ({
                  blockA: {
                    ...blockA,
                    value: {
                      ...blockA.value,
                      open: !blockA.value.open,
                    },
                  },
                })),
            },
          },
          blockB: {
            open: false,
            toggle: () =>
              set(({ blockA, blockB }) => ({
                blockB: {
                  ...blockB,
                  open: !blockB.open,
                },
                blockA: {
                  ...blockA,
                  open: blockB.open ? true : blockA.open,
                },
              })),
            chart: {
              open: true,
              toggle: () =>
                set(({ blockB }) => ({
                  blockB: {
                    ...blockB,
                    chart: {
                      ...blockB.chart,
                      open: !blockB.chart.open,
                    },
                  },
                })),
            },
            zoom: {
              open: false,
              toggle: () =>
                set(({ blockB }) => ({
                  blockB: {
                    ...blockB,
                    zoom: {
                      ...blockB.zoom,
                      open: !blockB.zoom.open,
                    },
                  },
                })),
            },
            table: {
              open: true,
              toggle: () =>
                set(({ blockB }) => ({
                  blockB: {
                    ...blockB,
                    table: {
                      ...blockB.table,
                      open: !blockB.table.open,
                    },
                  },
                })),
            },
          },
        }) as VisibilityForecastElements,
      {
        name: 'visibilityForecastElements',
        merge: (persistedState: unknown, currentState: VisibilityForecastElements): VisibilityForecastElements =>
          mergeObjects(currentState, persistedState as VisibilityForecastElements),
      },
    ),
    {
      store: 'visibilityForecastElements',
    },
  ),
)
