import storage from 'config/storage'
import {
  useRealignmentErrors,
  useRealignmentFilter,
  useRealignmentTable,
  useRealignmentTabs,
} from 'demand/realignment/store'

export const useClearAppCashDemand = () => {
  const clearRealignmentErrors = useRealignmentErrors((state) => state.clear)
  const clearRealignmentFilters = useRealignmentFilter((state) => state.clearFilters)
  const clearRealignmentTable = useRealignmentTable((state) => state.clear)
  const clearRealignmentTabs = useRealignmentTabs((state) => state.clear)

  const clearAppCashDemand = () => {
    storage.removeItem('demand.realignmentTable')
    clearRealignmentErrors()
    clearRealignmentFilters()
    clearRealignmentTable()
    clearRealignmentTabs()
  }

  return { clearAppCashDemand }
}
