export enum StatusTabsCodes {
  All = 'all',
  Check = 'check',
  Inactive = 'inactive',
  Archive = 'archive',
}

export interface UseCurrentStatusTab {
  currentTab: StatusTabsCodes
  set: (currentTab: StatusTabsCodes) => void
  clear: () => void
}
