import { devtools } from '@jume/utils'
import { create } from 'zustand'

import { UseForecastCalculatingSent } from './types'

export const useForecastCalculatingSent = create<UseForecastCalculatingSent>()(
  devtools(
    (set) => ({
      forecastCalculatingSent: false,
      setForecastCalculatingSent: (forecastCalculatingSent) => set({ forecastCalculatingSent }),
    }),
    {
      store: 'forecastCalculatingSent',
    },
  ),
)
