import { ColoredValue } from '@jume/ui'
import { CellContext } from '@tanstack/table-core'
import { DifferenceCell as DifferenceCellType, DifferenceRow } from 'prgm/scorecard/interfaces'
import { numberToLocalString } from 'utils/numberToLocalString'

export const DifferenceCell = ({ getValue }: CellContext<DifferenceRow, DifferenceCellType>) => {
  const value = getValue()

  return value?.color ? (
    <ColoredValue color={value.color}>
      <span data-copy-text={value.val.toFixed(2)} />
      {numberToLocalString(value.val.toFixed(2))}
    </ColoredValue>
  ) : (
    value
  )
}
