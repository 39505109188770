import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'packages/app'

export const Route = createFileRoute('/opti/promo-optimizer/')({
  component: createPage({
    titleKey: 'titles.optimize',
    lazyImport: {
      fn: () => import('opti/promoScenario/components/PromoScenariosList'),
      name: 'PromoScenariosList',
    },
  }),
})
