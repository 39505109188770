import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'packages/app'

export const Route = createFileRoute('/opti/create-promo-scenario')({
  component: createPage({
    titleKey: 'titles.createPromoScenario',
    lazyImport: {
      fn: () => import('opti/promoScenario/components/CreatingPromoScenarioBlock'),
      name: 'CreatingPromoScenarioBlock',
    },
  }),
})
