import { GetAllData } from '@jume/api'
import { PaginateData } from '@jume/query'
import { TransitionTableRow } from 'jume/transitionTable/api'

export enum TransitionTabsCodes {
  All = 'all',
  Completed = 'completed',
}

export type TransitionAllData = Record<TransitionTabsCodes, PaginateData<GetAllData<TransitionTableRow>> | undefined>
