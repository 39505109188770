import { FC } from 'react'

import { LogoutIcon } from '@jume/icons'
import { t } from '@jume/localization'
import { ConfirmModal } from '@jume/ui'
import { useModal } from 'hooks/useModal'
import { useIsDirty } from 'packages/app'
import { useLogoutByAppContext } from 'packages/app/modules/logout'
import { AppContextProps } from 'packages/app/providers'

import classes from './LogoutMenuItem.module.scss'

interface Props {
  appContext: AppContextProps
}

export const LogoutMenuItem: FC<Props> = ({ appContext }) => {
  const [openConfirm, onOpenConfirm, onCloseConfirm] = useModal()
  const { isDirty } = useIsDirty()
  const { logout } = useLogoutByAppContext(appContext)

  const onOk = () => {
    if (isDirty) {
      appContext.auth.openLogoutUnsavedChanges?.()
    } else {
      logout()
    }
  }

  return (
    <div className={classes.menuExit}>
      <span className={classes.exit} onClick={onOpenConfirm}>
        <LogoutIcon className={classes.icon} />
        {t('auth.logout')}
      </span>
      <ConfirmModal
        closeAfterSuccess
        danger
        isOpened={openConfirm}
        okText={t('auth.logout')}
        onClose={onCloseConfirm}
        onOk={onOk}
        title={t('auth.sureLogout')}
      />
    </div>
  )
}
