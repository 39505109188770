import { devtools } from '@jume/utils'
import { create } from 'zustand'

import { UseJobsParams } from './types'

export const useJobsParams = create<UseJobsParams>()(
  devtools(
    (set) => ({
      search: '',
      setSearch: (search) => set({ search }),
      clearSearch: () => set({ search: '' }),
    }),
    {
      store: 'jobsParams',
    },
  ),
)
