import { devtools } from '@jume/utils'
import { create } from 'zustand'
import { persist, subscribeWithSelector } from 'zustand/middleware'

export interface UseOpenMenu {
  open: boolean
  setOpen: (open: boolean) => void
  toggleOpen: () => void
  clear: () => void
}

export const useOpenMenu = create<UseOpenMenu>()(
  subscribeWithSelector(
    devtools(
      persist(
        (set, get) => ({
          open: true,
          setOpen: (open) => set({ open }),
          toggleOpen: () => set({ open: !get().open }),
          clear: () => set({ open: true }),
        }),
        {
          name: 'app.openMenu',
        },
      ),
      {
        store: 'app.openMenu',
      },
    ),
  ),
)
