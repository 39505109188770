import { Fragment } from 'react'

import { isMenuComponent, isMenuGroup, MenuItem } from '@jume/core'
import { NavLink } from '@jume/ui'

import classes from './NavMenu.module.scss'
import { NavSubMenu } from './NavSubMenu'

export const renderMenu = (item: MenuItem, index: number) => {
  if (isMenuGroup(item)) {
    return <NavSubMenu item={item} key={index} />
  }
  if (isMenuComponent(item)) {
    return <Fragment key={index}>{item.component}</Fragment>
  }
  return (
    <NavLink className={classes.menuItem} key={index} path={item.href}>
      {item.label()}
    </NavLink>
  )
}
