import { createEndpoint, NoParamEndpointConstructor } from '@jume/core'

const baseUrl = '/account'

interface Endpoints {
  getPermissions: NoParamEndpointConstructor
}

export const permissionsEndpoints: Endpoints = {
  getPermissions: () => createEndpoint(`${baseUrl}/permissions`),
}
