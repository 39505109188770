import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'packages/app'

export const Route = createFileRoute('/login')({
  component: createPage({
    titleKey: 'titles.login',
    lazyImport: {
      fn: () => import('packages/app/modules/login'),
      name: 'AuthBlock',
    },
  }),
})
